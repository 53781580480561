import gpl from 'graphql-tag'

export const GET_SEOMATIC_TAGS = gpl`
query($uri:String) {
  seomatic(uri: $uri) {
      metaTitleContainer
      metaTagContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
  }
}`
