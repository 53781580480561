<template>
  <div>
    <section id="slider" class="slider-parallax revoslider-wrap clearfix text-center">
      <div class="tp-banner-container">
        <div class="tp-banner" style="">
          <div>
            <div class="slotholder video" :style="'height:' + window.height + 'px'">
              <div v-if="!contentrows.videoId">Loading</div>
            <iframe v-if="contentrows.videoId" :src="'https://player.vimeo.com/video/' + contentrows.videoId + '?autoplay=1&loop=1&autopause=0&muted=1'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            <div class="overlay" :style="'opacity:' + contentrows.videoOverlayTransparency + '; background-color:' + contentrows.videoOverlayColor + ';'"></div>
          </div>
          </div>
        </div>
      </div>
    </section>
    
    <div v-if="showcontentrows" class="arrow-down animated hidden-xs hidden-sm">Scroll for at se mere</div>
    <Contentrows v-if="showcontent"
                 v-bind:gmap="gmap"
                 v-bind:carousel="carousel"
                 v-bind:contentrows="contentrows"
                 v-bind:globalSet="globalSet"/>
  </div>
</template>

<script>  
  import ContentpageContentrows from './ContentpageContentrows'
  import { GET_CONTENTPAGE_QUERY } from '../graphql/contentrowsQueries';

  export default {
     name: 'Videocontent',
     props: ['globalSet'],
     components: {
        'Contentrows': ContentpageContentrows
     },
     data() {
       return {
           contentrows: [],
           gmap: [],
           carousel: [],
           showcontent: false,
           window: {
               width: 0,
               height: 0
           }
       }
     },
     created() {
         this.fetchData()
         this.handleResize()
     },
     watch: {
         $route () {
             this.contentrows = []
             this.gmap = []
             this.carousel = []
             this.fetchData()
         }
     },
     methods: {
         handleResize () {
             this.window.width = window.innerWidth;
             this.window.height = window.innerHeight;
         },
         fetchData () {
             this.$apollo.query({
                 query: GET_CONTENTPAGE_QUERY,
                 variables: {
                     slug: window.location.pathname.replace('/', '')
                 }
             }).then(function (response) {
                 if(response.data.contentrows[0]) {
                     this.contentrows = response.data.contentrows[0]
                     if(this.contentrows.content_content_grid.length > 0 ) {
                         this.showcontent = true
                     }
                 }
                 if(response.data.gmap[0]) {
                     this.gmap = response.data.gmap[0]
                 }
                 if(response.data.carousel[0].content_content_grid[0]) {
                     this.carousel = response.data.carousel[0].content_content_grid[0].image_carousel
                 }
              }.bind(this))
         }
     }
  }
</script>

<style>
</style>
