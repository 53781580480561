<template>
 <section id="slider" class="slider-parallax revoslider-wrap clearfix text-center">
   <div class="tp-banner-container">
     <div class="tp-banner" style="">
       <div v-if="loading" class="loading">
         Loading...
       </div>
       <div v-if="entries">
         <carousel :perPage=1 :navigationEnabled="false" :autoplay="true" :autoplayHoverPause="true" :paginationEnabled="false" :autoplayTimeout=15000 :loop="true">
           <slide v-for="entry in entries" v-bind:key="entry">
<div class="slotholder" v-if="entry.mediaType ==='image'">
             <div v-for="image in entry.slide_image" v-bind:key="image">
               <img v-lazy="image.url"  alt="video_typing_cover"  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat">
             </div>
             <div class="overlay" :style="'opacity:0.3; background-color:'+ entry.overlayColor +';'"></div>
           </div>
           <div class="slotholder video" v-if="entry.mediaType ==='youtubeVideo'">
             <iframe v-if="entry.youtubeId" src="'https://www.youtube.com/embed/'+ entry.youtubeId + '?rel=0&controls=0&showinfo=0&autoplay=1&loop=1&playlist=&mute=1'" frameborder="0" allowfullscreen></iframe>
             <div class="overlay" :style="'opacity:0.3; background-color:'+ entry.overlayColor +';'"></div>
           </div>
           <div class="slotholder video" v-if="entry.mediaType ==='vimeoVideo'">
             <iframe v-if="entry.vimeoId" :src="'https://player.vimeo.com/video/'+ entry.vimeoId +'?quality=auto&autoplay=1&loop=1&autopause=0&muted=1'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
             <div class="overlay" :style="'opacity:0.3; background-color:'+ entry.overlayColor +';'"></div>
           </div>
           <div v-if="entry.headline" class="hidden-sm hidden-xs headline" v-html="entry.headline"></div>
           <div v-if="entry.sub_headline" class="hidden-sm hidden-xs subheadline" v-html="entry.sub_headline"></div>
           <div v-if="entry.headlineResponsive" class="hidden-md hidden-lg headlineResponsive" v-html="entry.headlineResponsive"></div>
           <div v-if="entry.sub_headlineResponsive" class="hidden-md hidden-lg subheadlineResponsive" v-html="entry.sub_headlineResponsive"></div>

           <div class="buttons">
             <a v-if="entry.button1_link" :href="entry.button1_link" :target="entry.link_1_destination" class="button button-border slide-button button-white button-light button-large button-rounded tright nomargin">
               <span>{{ entry.button1_text }}</span>
               <i class="icon-angle-right"></i>
             </a>
             <a v-if="entry.button2_link" :href="entry.button2_link" :target="entry.link_2_destination" class="button button-large button-rounded slide-button tright nomargin" style="color: #fff;">
               <span>{{ entry.button2_text }}</span>
               <i class="icon-angle-right"></i>
             </a>
           </div>
           </slide>
         </carousel>
       </div>
     </div>
     <div ref="arrowdown" class="arrow-down animated hidden-xs hidden-sm">Scroll for at se mere</div>
   </div>
 </section>
 </template>

<script>
import { GET_SLIDER_QUERY } from '../graphql/sliderQueries';
import { Carousel, Slide } from 'vue-carousel';
                                     
export default {
  name: 'slider',
    data() {
      return {
        loading: false,
        entries: []
    }
   },
   components: {
      Carousel,
      Slide,
   },
   created() {
      //this.data = this.fetchData()
   },
   apollo: {
     entries: GET_SLIDER_QUERY
   },
   mounted() {
     this.arrowAnimate()
   },
   methods: {
      //fetchData () {
      //  this.loading = false
      //  this.$apollo.query({ query: GET_SLIDER_QUERY }).then(function (response) {
      //     var i;
      //     var data = [];        
      //     for (i = 0; i < response.data.entries.length; i++) {                               
      //       data.push = response.data.entries[i].mediaType
      //     }
      //     console.log(data)
      //     this.data = data                
      //  }.bind(this));
                             //}
      arrowAnimate () {
          var bounceEffect = 'bounce'
          var fadeInEffect = 'fadeInDown'
          var arrow = this.$refs.arrowdown

          function addEffect() {
             arrow.classList.remove(fadeInEffect)
             arrow.classList.add(bounceEffect)
          }
          function removeEffect() {
             arrow.classList.remove(bounceEffect)
          }
        
          setInterval(addEffect, 4000)
          setTimeout(function() {
             setInterval(removeEffect, 4000)
          }, 2000)
      }
    },
}
</script>

<style>
</style>
