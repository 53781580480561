import gql from 'graphql-tag'

export const GET_FRONTPAGE_CONTENTROWS_QUERY = gql`
query {
  entries (section:"adminchannel", slug:"__home__") {
    ... on adminchannel_adminchannel_Entry {
      contentgrid {
        ... on contentgrid_contentrows_BlockType {
          headline,
          text,
          url_link,
          link_text,
          medie_type,
          video_type,
          youtube_video,
          vimeo_video,
          image {
            url
          },
          medie_position,
          medie_width
        }
      }
    }
  }
}`

export const GET_CONTENTPAGE_QUERY = gql`
query ($slug:[String]) {
  contentrows: entries (section:"contentpage", slug: $slug) {
    ... on contentpage_contentpage_Entry {
      title,
      text_content,
      videoOverlayTransparency,
      useTemplate,
      content_sub_title,
      content_transparent_header,
      videoOverlayColor,
      videoId,
      content_content_grid(type: "contentrows") {
        ... on content_content_grid_contentrows_BlockType {
          typeHandle,
          headline,
          text,
          link_text,
          url_link,
          medie_type,
          form {
             id
          },
          video_type,
          youtube_video,
          vimeo_video,
          billede {
            url,
            title
          },
          medie_position,
          medie_width,
          column,
          breakColumn
        }
      }
    }
  }
  gmap: entries (section:"contentpage", slug: $slug) {
      ... on contentpage_contentpage_Entry {
      content_content_grid(type:"include_gmap") {
        ... on content_content_grid_include_gmap_BlockType {
          latitude,
          longtitude,
          markerIcon {
            url
          },
          markerHeadline,
          markerText,
          markerLink,
          markerLinkText,
          markerImage {
            url
          }
        }
      }	
    }
  }
  carousel: entries (section:"contentpage", slug: $slug) {
     ... on contentpage_contentpage_Entry {
     content_content_grid(type:"image_carousel_content") {
        ... on content_content_grid_image_carousel_content_BlockType {
          image_carousel {
             url,
             title
          }
        }
     }
   }
  }
}`
