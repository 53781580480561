<template>
  <div>
    <section id="page-title">
      <div class="container clearfix">
        <h1>Ledige lejligheder</h1>
      </div>
    </section>
  <section id="content" class="extendedpropertylist">
    <div class="content-wrap">
      <div class="container clearfix" id="propertyview">
        <div class="row" v-if="globalSet[0]">
          <div class="col-md-12 col-xs-12 col-sm-12" v-html="globalSet[0].listViewFirstTextField"></div>
          <div class="col-md-12 col-xs-12 col-sm-12" v-if="loadPropertyView">
            <div id="lejlighed-billede" class="col-sm-6 col-md-6 col-xs-12 apartmentimages">
              <carousel
                :perPage="1"
                :navigationEnabled="true"
                :navigation-next-label="navigationNext"
                :navigation-prev-label="navigationPrev"
                :paginationEnabled="false">
                
                <slide v-for="(image, id) in propertyImages" :key="image">
                  <div class="images-wrapper">
                    <img :src="image.url" :alt="image.title" :key="0">
                    <a class="image image-zoom" data-gallery="gallery" :key="0" @click="index = id"></a>
                  </div>
                </slide>
              </carousel>
            </div>
            <div id="lejlighed-info" class="col-sm-6 col-md-6 col-xs-12 col_last">
              <h3>Information</h3>
              <ul class="list-unstyled apartmentinfo">
                <li v-for="propertyInfo in propertyData" :key="propertyInfo">
                  <div v-if="propertyInfo.type === 'link'">
                    {{ apartment.name }}: <span class="pull-right">
                      <a :target="propertyInfo.target"
                         :alt="propertyInfo.linkalt"
                         :rel="propertyInfo.linkrel"
                         :href="propertyInfo.link">
                        {{ propertyInfo.value }}
                      </a>
                    </span>                    
                  </div>
                  <div v-if="propertyInfo.type != 'link'">
                    {{ propertyInfo.name }}: <span class="pull-right">
                      {{ propertyInfo.value }}
                    </span>             
                  </div>
                </li>
                <li>
                <div>
                    <a href='#' class="btn btn-default button" style="margin-top:10px;" @click="showModal">Kontakt mig</a>
                </div>
              </li>
                
              </ul>
            </div>            
          </div>
          <div class="col-md-12 col-xs-12 col-sm-12">
            <div id="lejlighed-label" class="clearfix nobottommargin pull-right">
              <div v-if="stateRent" class="fright">
                <p class="nobottommargin center">Til leje</p>
                <p class="nobottommargin status" status-type="rent" status-availability="free"></p>
              </div>
              <div v-if="stateSales" class="fright">
                <p class="nobottommargin center">Til salg</p>
                <p class="nobottommargin status" status-type="buy" status-availability="free"></p>
              </div>

              <div v-if="stateOnhold" class="fright">
                <p class="nobottommargin center">Reseveret</p>
                <p class="nobottommargin status" status-availability="reserved"></p>
              </div>
              <div v-if="stateRentAndSold" class="fright">
                <p class="nobottommargin center">Udlejet</p>
                <p class="nobottommargin status" status-type="rent" status-availability="occupied"></p>
              </div>
              <div v-if="stateSaleAndSoled" class="fright">
                <p class="nobottommargin center">Solgt</p>
                <p class="nobottommargin status" status-type="buy" status-availability="occupied"></p>
                </div>
              <div v-if="stateSupply" class="fright">
                <p class="nobottommargin center">August 2019</p>
                <p class="nobottommargin status" status-type="buy" status-availability="supply"></p>
              </div>
            </div>

          </div>       
          <div class="col-md-12 col-sm-12 col-xs-12">
            <h3>Filtrere oversigten</h3>
            <div class="pull-left">
              <span class="toggleLabel">Ledige:</span>
              <label class="switch">                
                <input type="checkbox" @click="toggle" checked>
                <div class="slider round"></div>
              </label>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <vue-good-table
              :columns="columnsDA"
              styleClass="vgt-table condensed"
              :rows="properties"
              :search-options="{ enabled: true, placeholder: 'Søg', }"
              @on-row-click="onRowClick"
              :row-style-class="rowStyleClassFn"
              compactMode/>
          </div>
          <div class="col-md-12 col-xs-12 col-sm-12" v-html="globalSet[0].listViewSecondTextField"></div>
        </div>
      </div>
    </div>
    <CoolLightBox :items="imageItems"
                  :index="index"
                  @close="index = null">
    </CoolLightBox>

  </section>
    <ContactMeModal
      v-show="isModalVisible"
      @close="closeModal"
      v-bind:headline="'Bliv kontaktet af os'"
      v-bind:selectedApartment="selectedApartment"
    /> 
  </div>
</template>

<script>
  import { GET_ALL_PROPERTY_QUERY } from '../graphql/buildingQueires'
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table'
  import { Carousel, Slide } from 'vue-carousel';
  import CoolLightBox from 'vue-cool-lightbox'
  import {scroller} from 'vue-scrollto/src/scrollTo'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  import ContactMeModal from './ContactMeModal'

  const firstScrollTo = scroller()
  
  export default {
      name: 'Apartmentlist',
      props: ['globalSet'],
      components: {
          VueGoodTable,
          Carousel,
          Slide,
          CoolLightBox,   
          ContactMeModal
  },
  computed: {
  navigationNext: function() { return `<i class="fas fa-chevron-right"></i>` },
  navigationPrev: function() { return `<i class="fas fa-chevron-left"></i>` },
  },
      data() {
          return {
              imageItems: [],
              index: null,
              propertyImages: [],
              properties: [],
  propertyData: [],
  selectedApartment: '',
  loadPropertyView: false,
  isModalVisible: false,              
  lastClickEvent: false,
  searchFilter: true,
              stateRent: false,
              stateSales: false,
              stateOnhold: false,
              stateRentAndSold: false,
              stateSaleAndSoled: false,
              stateSupply:false,
              columnsDA: [ {label: 'Bolignr', field: 'num', thClass: 'text-left', tdClass: 'text-left', width: '5%'},
                         {label: 'Adresse', field: 'address', thClass: 'text-left', tdClass: 'text-left', width: '25%'},                           
                         {label: 'Type', field: 'type', thClass: 'text-left', tdClass: 'text-left', width:'15%'},
                         {label: 'Bygning', field: 'building', thClass: 'text-left', tdClass: 'text-left', width:'20%'},
                         {label: 'Husleje', field: 'monthlyRent', thClass: 'text-left', tdClass: 'text-left', width:'5%'},                         
                         {label: 'Areal', field: 'areal', thClass: 'text-left', tdClass: 'text-left', width:'5%'},
                         {label: 'Værelser', field: 'rooms', thClass: 'text-right', tdClass: 'text-right', width:'10%'},
                         {label: 'Ledighedstidspunkt', field: 'availablityDate', thClass: 'text-left', tdClass: 'text-left', width:'10%'},                         
              ],
              options: { },
          }
      },
      created () {
          this.fetchData()
      },
  methods: {
              showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
          toggle() {
             this.searchFilter = this.searchFilter ? false : true;
             this.properties = []
             this.fetchData()
          },
          onRowClick(params) {
              if(params.event.target.localName == 'span') {
                  params.event.target.parentNode.parentNode.classList.toggle('activeRow')
              } else {
                  params.event.target.parentNode.classList.toggle('activeRow')
              }
  
              if(this.lastClickEvent != false ) {
                  if(this.lastClickEvent.event.target.localName == 'span') {
                      this.lastClickEvent.event.target.parentNode.parentNode.classList.toggle('activeRow')
                  } else {
                      this.lastClickEvent.event.target.parentNode.classList.toggle('activeRow')
                  }
              }
              this.lastClickEvent = params
              this.updatePropertyInfo(params.row.id)
          },
          rowStyleClassFn(row) {
              if(row.extra.lejligheds_type == 'rent' && row.extra.lejlighed_status == 'available') {
                  return 'available-for-rent';
              }
              if(row.extra.lejligheds_type == 'buy' && row.extra.lejlighed_status == 'available') {
                  return 'available-for-sale';
              }
              if(row.extra.lejlighed_status == 'onhold') {
                  return 'onhold';
              }
              if(row.extra.lejligheds_type == 'buy' && row.extra.lejlighed_status == 'available') {
                  return 'available-for-sale';
              }
              if(row.extra.lejligheds_type == 'rent' && row.extra.lejlighed_status == 'soled') {
                  return 'notavailable-for-rent';
              }
              if(row.extra.lejligheds_type == 'buy' && row.extra.lejlighed_status == 'soled') {
                  return 'notavaliable-for-sale';
              }
          },
          fetchData() {
              this.$apollo.query({
                  query: GET_ALL_PROPERTY_QUERY,
              }).then(function (response) {
                      var property = {}
                         if(response.data.property.length > 0) {
                          var x=0
                          for(let i = 0; i < response.data.property.length; i++) {
                              if(response.data.property[i].boligtyper[0] != undefined) {
                                           
                                  property = {
                                      id: i,   
                                      num: response.data.property[i].bolignr,
                                      type: response.data.property[i].boligtyper[0].title,
                                      building: response.data.property[i].bolig_blok[0].title,
                                      areal: response.data.property[i].strelse,
                                      rooms: response.data.property[i].boligtyper[0].amount_of_rooms,
                                      monthlyRent: response.data.property[i].monthlyRent,
                                      address: response.data.property[i].address,
                                      availablityDate: response.data.property[i].availablityDate,
                                      extra: response.data.property[i]
                                  }           
                                  if(this.searchFilter) {
                                             if(response.data.property[i].lejlighed_status == 'available' &&  response.data.property[i].lejligheds_type == 'rent') {
                                             property.id = x
                                             this.properties.push(property)
                                             x++
                                             }
                                             if(response.data.property[i].lejlighed_status == 'onhold' &&  response.data.property[i].lejligheds_type == 'rent') {
                                             property.id = x
                                             this.properties.push(property)
                                             x++
                                             }
                                  } else {
                                      this.properties.push(property)
                                  }
                              }
                          }
                          for(let i=0; i < this.properties.length; i++) {
                              if(this.properties[i] != undefined) {
                              if(this.properties[i].extra.lejligheds_type == 'rent' &&
                                  this.properties[i].extra.lejlighed_status == 'available') {
                                  this.stateRent = true
                              }
                              if(this.properties[i].extra.lejligheds_type == 'buy' &&
                                  this.properties[i].extra.lejlighed_status == 'available') {
                                  this.stateBuy = true
                              }
                              if(this.properties[i].extra.lejlighed_status == 'onhold') {
                                  this.stateOnhold = true
                              }
                              if(this.properties[i].extra.lejligheds_type == 'rent' &&
                                  this.properties[i].extra.lejlighed_status == 'soled') {
                                  this.stateRentAndSold = true
                              }
                              if(this.properties[i].extra.lejligheds_type == 'buy' &&
                                  this.properties[i].extra.lejlighed_status == 'soled') {
                                  this.stateSaleAndSold = true
                              }
                              if(this.properties[i].extra.lejlighed_status == 'supply') {
                                  this.stateSupply = true
                                           }
                                           }
                                           }
                                           }
                  }.bind(this))
           },
           updatePropertyInfo(id) {
               var propertyId = id
               var property = this.properties[propertyId].extra
               this.propertyImages = property.apartment_images
               this.selectedApartment = property.bolignr + ' - ' + property.boligtyper[0].title
               for(let x=0; x < this.propertyImages.length; x++) {
                   this.imageItems[x] = this.propertyImages[x].url
               }
               this.loadPropertyView = true
               firstScrollTo('.extendedpropertylist')
               this.propertyData = []

               //if(property.pricelabel) {
               //    var pricelabel = property.pricelabel
               //} else {
               //        pricelabel = 'Pris'
               //}
                                
               this.propertyData = [
                                { 'type': '', 'name': 'Bolignr', 'value': property.bolignr },
                                { 'type': '', 'name': 'Boligtype', 'value': property.boligtyper[0].title },
                                { 'type': '', 'name': 'Boligens brutto areal', 'value': property.strelse },
                                { 'type': '', 'name': 'Adresse', 'value': property.address },
                                { 'type': '', 'name': 'Husleje', 'value': property.monthlyRent },
                                { 'type': '', 'name': 'Ledighedstispunkt', 'value': property.availablityDate }
               ]
               
                                
               for(let i=0; property.information.length; i++) {
                   var propertyrow = { 'type': property.information[i].info_link,
                                    'name': property.information[i].info_name,
                                    'value': property.information[i].info_value,
                                    'link': property.information[i].info_link,
                                    'target': property.information[i].info_linktarget,
                                    'linkrel': property.information[i].info_linkrel,
                                    'linkalt': property.information[i].info_linkalt
                   }
                                
                   this.propertyData.push(propertyrow)
               }
          }
       }
     }
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #84C87E;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.toggleLabel {
    float: left;top: 4px;display: block;position: relative;padding-right: 10px;
}
table.vgt-table {
    font-size: 14px;
}
</style>
