<template>
   <div>
     <section id="slider" class="buildingview-container" style="top: -100px">
       <div class="col-md-12 hidden-xs hidden-sm nopadding boligblok_view">
         <div class="gradientoverlay"></div>
         <h1>Vælg en markering og se tilgængeligheden af lejemål</h1>

         <svg
           xmlns:dc="http://purl.org/dc/elements/1.1/"
           xmlns:cc="http://creativecommons.org/ns#"
           xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
           xmlns:svg="http://www.w3.org/2000/svg"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink"
           width="1920"
           height="1250"
           viewBox="0 0 507.99999 330.72918">
           <image
             y="0.1699228"
             x="0.1755638"
             id="image925"
             xlink:href="//hpl-husene.dk/assets/images/hpl-husene-oversigt-rev3.webp"
             preserveAspectRatio="none"
             height="330.72916"
             width="508" />
           <g transform="translate(0,33.729184)">
             <path
               d="m 212.04464,208.69552 -2.45685,-56.50744 1.55916,-6.00038 5.6224,0.0945 0.58726,-3.27602 45.836,-0.58678 0.42187,7.22545 36.69284,-0.37176 0.79985,2.18433 7.04792,-0.26104 2.63582,6.54321 -4.3231,70.13822 -7.29967,0.23623 -0.0236,3.59077 -41.86085,0.0473 -0.42522,-3.54353 -7.15792,-0.0472 -0.0709,-11.69364 -19.30041,0.23624 v -8.19736 z"
               id="path928"
               class="marker2"
               inkscape:label="Seebladsgade 9"
               @click="goto('/bygning/lejlighedsoversigt-bygning-9-nord-vest-odense')"/>
             <path
               d="m 154.72456,211.09565 -3.96876,-1.98437 -1.22842,-5.43341 11.33405,-5.32819 -2.53824,-1.25724 -0.10236,-5.69879 12.23698,-4.03963 -4.39397,-1.58277 -0.16536,-5.71689 3.37815,-1.48829 -1.49045,-24.83117 -3.9902,-1.13107 -0.56696,-7.32328 -12.13438,-3.37886 -7.42589,1.86696 -0.13363,-2.96748 -26.89167,-7.61586 -8.69345,2.26786 -3.43618,-0.95542 -16.783582,4.30185 3.021844,1.18928 -5.079752,0.94494 1.677967,10.01637 -16.630953,4.1105 -10.016369,2.45684 2.976562,1.46466 -6.378347,1.98437 2.078868,8.12649 -3.260044,-1.03943 9.307662,49.04241 27.78125,15.68601 36.427454,-14.22135 3.44904,1.55915 z"
               id="path930"
               class="marker2"
               inkscape:label="Sebladsgade 11"
               @click="goto('/bygning/lejlighedsoversigt-bygning-11-nord-odense')"/>
             <path
               d="m 330.56309,204.83375 40.9875,19.8218 3.96875,-1.13393 3.40277,1.75625 13.0392,-4.21309 20.78869,10.01636 11.40906,-4.76761 20.15194,6.6575 3.77976,-0.75596 6.84747,2.83482 27.35937,-13.79613 7.8802,-36.25034 -3.13353,1.22171 2.82333,-12.29569 -6.15928,-1.83453 2.79092,-1.35633 -20.62163,-5.56941 1.51188,-7.43637 -10.62721,-2.58098 2.37757,-1.15686 -18.6086,-4.93465 -3.85804,1.64554 -5.66965,-1.77819 -23.41063,6.48095 -20.6675,-5.65818 -21.33565,5.61331 -2.1552,-0.91153 -14.16067,3.79024 -0.34557,6.48095 -11.63995,3.31302 -1.07857,14.2858 -3.96875,-1.7009 -1.04419,14.8508 3.87902,1.78016 -0.2663,5.913 z"
               id="path932"
               class="marker2"
               inkscape:label="Seebladsgade 7"
               @click="goto('/bygning/lejlighedsoversigt-bygning-7-vest-odense')"/>
             <text x="110.29289" y="190.39891" text-anchor="middle">
               <tspan x="110.29289" y="190.39891">Seebladsgade 11</tspan>
             </text>
             <text x="260.21529" y="190.39888" text-anchor="middle">
             <tspan x="260.21529" y="190.39888">Seebladsgade 9</tspan>
             </text>
             <text x="410.83749" y="190.33206" text-anchor="middle">
               <tspan x="400.83749" y="190.33206">Seebladsgade 7</tspan>
             </text>
           </g>
         </svg>
       </div>
     </section>
     <div class="row">
       <div class="col_full hidden-md hidden-lg boligblock_view responsive-blockview">
         <div v-for="building in buildings" :key="building">
           <h4>{{ building.title }}</h4>
           <h5>{{ building.buildingLabel }}</h5>
           <router-link :to="building.uri"><img :src="building.facade[0].url" :alt="building.facade[0].title"></router-link>
         </div>
       </div>
     </div>
   </div>
</template>

<script>
  import { GET_BUILDINGS_QUERY } from '../graphql/buildingQueires'

  export default {
      name: 'Buidldingview',
      data() {
          return {
              buildings: []
          }
      },
      mounted() {
          this.fetchData()
      },
      methods: {
          goto(url) {
             this.$router.push(url)
          },
          fetchData () {
              this.$apollo.query({
                  query: GET_BUILDINGS_QUERY,
          
              }).then(function (response) {
                  if(response.data.building.length > 0) {
                      this.buildings = response.data.building
                  }
              }.bind(this))
           },

      }
  }
  
</script>

<style>
</style>
