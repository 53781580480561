 <template>
   <footer id="footer" class="dark">			
     <div id="copyrights" style="padding: 20px 0;">
       <div class="container clearfix">
         <div class="row" v-for="global in globalSet" v-bind:key="global">
           <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="">
             <a href="/" :alt="global.footer_logo_alt" target="_blandk" v-for="footer_logo in global.footer_logo" v-bind:key="footer_logo">
               <img :src="footer_logo.url" :alt="global.footer_logo_alt" class="footer-logo">
             </a>
           </div>
           <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 realestate-contact" style="" v-html="global.footerColumn1">
           </div>
           <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 tright" style="" v-html="global.footerColumn2">
           </div>
         </div>
         <div class="row" style="display:flex;">
           <div class="col-md-3 col-lg-3 col-xl-3 hidden-xs hidden-sm" style="flex:1;">                 
             <router-link style="padding-top:5px;" to="/cookiepolitik">Cookie-/persondatapolitik</router-link>
             <div style="padding-top:10px;"><a alt="Digital web- og marketingbureau" target="_blank" href="http://cube.dk">Designed by Cube Marketing</a></div>
           </div>
           <div class="col-md-9 col-lg-9 col-xl-9 tright" style="flex:3;">
             <div class="copyrights-menu copyright-links">
               <ul>
                 <router-link to="leje-lejligheder-studieboliger-odense-city-campus">HPL-Husene</router-link> /       
                 <router-link to="/leje-lejligheder-studieboliger-odense-oversigt">Boligoversigt</router-link> /
                 <router-link to="/boligtyper">Boligtyper</router-link> /
                 <router-link to="/galleri">Galleri</router-link> /
                 <router-link to="/kontakt">Kontakt</router-link>
               </ul>
             </div>
             <div class="col-xs-12 col-sm-12 hidden-md hidden-lg hidden-xl" style="margin-bottom: 0; text-align:center;">                 
               <a style="padding-top:5px;" href="/c/cookie-politik">Cookie-/persondatapolitik</a>
               <div style="padding-top:10px;"><a alt="Digital web- og marketingbureau" target="_blank" href="http://cube.dk">Designed by Cube Marketing</a></div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </footer>
 </template>

<script>
  export default {
    name: 'Footer',
    props: ['globalSet']
  }
</script>

<style>
</style>
