 <template>
 <div>
    <section id="page-title">
      <div class="container clearfix">
        <h1>Galleri</h1>
      </div>
    </section>

    <section id="content">
      <div class="content-wrap">
        <div class="container clearfix">
          <div class="col_full clearfix">
            <ul id="portfolio-filter" class="clearfix">
              <li v-for="category in categories" :key="category" @click="filter">
                <a :href="'#'+category.slug">{{ category.title }}</a>
              </li>
              <li class="activeFilter" @click="filter()"><a href="#">Vis alle</a></li>
            </ul>
            <div class="clear"></div>

            <div id="gallery" class="grid">
              <span v-for="(gallery, id) in images"
                 :key="gallery"
                   :class="'gallery-item gallery-category-' + gallery.slug" @click="index = id">
                <img v-lazy="getThumbImage(gallery.src)">
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CoolLightBox :items="images"
                  :index="index"
                  @close="index = null">
    </CoolLightBox>
 </div>
 </template>

<script>
  import { GET_GALLERIES } from '../graphql/galleryQueries'
  import CoolLightBox from 'vue-cool-lightbox'

  export default {
  name: 'Gallery',
     data() {
         return {
             index: null,
             images: [],
             categories: [],
             lastEvent: ''
         }
     },
     components: {
         CoolLightBox
     },
     mounted() {
         this.fetchData()
         var hash = window.location.hash.substring(1);
         if(hash) {
             this.filter()
         }
    },
    watch: {
        $route () {
           this.filterGallery()
       }
    },
    methods: {
        fetchData () {
            this.$apollo.query({
                query: GET_GALLERIES,
            }).then(function (response) {
                 for (let i=0; i < response.data.entries.length; i++) {
                     for (let x=0; x < response.data.entries[i].images.length; x++) {
                         var image = { 'src': response.data.entries[i].images[x].url,
                                       'title': response.data.entries[i].images[x].title,
                                       'slug': response.data.entries[i].slug }
                         this.images.push(image)
                      }
                      var category = { 'title': response.data.entries[i].title,
                                       'slug': response.data.entries[i].slug }
                      this.categories.push(category)
                 }                 
            }.bind(this))
        },
        getThumbImage(imgUrl) {
          var imgpath = imgUrl.replace('galleri/','galleri/_gallery_scale3/')
          return imgpath
        },
        getLargeImage(imgUrl) {
          var imgpath = imgUrl.replace('galleri/','galleri/_gallery_scale1/')
          return imgpath
        },
        filter: function(event) {
            if(this.lastEvent) {
               this.lastEvent.target.classList.remove('activeFilter')
            }
            if(event) {
                event.target.classList.add('activeFilter')
            }
            this.lastEvent = event                    
        },
        filterGallery() {
            var hash = window.location.hash.substring(1);

            var galleries = document.getElementsByClassName('gallery-item')
            if(hash == '' ) {                    
               for(let i=0; i < galleries.length; i++) {
                   galleries[i].hidden = false;
               }
            } else {
               for(let i=0; i < galleries.length; i++) {
                   galleries[i].hidden = true;
               }
            }
                                 
            var donothide = document.getElementsByClassName('gallery-category-'+hash)
                for(let i=0; i < donothide.length; i++) {
                    console.log(donothide[i])
                    donothide[i].hidden = false
                }            
        }
     }
  }
</script>

<style>
</style>
