<template>
  <div>
    <div v-if="apartments"
         class="col-md-7 col-sm-7 col-xs-12 apartmenttypes">
      <div v-for="(apartment, index) in apartments" :key="apartment">
        <div class="col-md-6 apartment" style="cursor:pointer;" :id="index" @click="updateInfo" v-scroll-to="'#apartmentfeatures'">
          <div class="apartment-box">
            <div class="apartment-image"><img :alt="apartment.image[0].titlte" :src="apartment.image[0].url"></div>
            <span>Se flere detaljer</span>
            <div class="apartment-info">
              <div style="float:left;">{{ apartment.title }}<BR/>{{ apartment.size }}</div>
              <div style="text-align:right; padding-top:5px;">
                <img src="/uploads/default/vr-icon.svg" style="width:20px;" v-if="apartment.vr_link">
                &nbsp;&nbsp;<img src="/uploads/default/360-icon.svg" style="width:30px;" v-if="apartment.link_360">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="apartmentfeatures" class="col-md-5 col-sm-5 col-xs-12 apartmentype-features">
      <h4>{{ apartmentHeadline }}</h4>
      <div class="si-share clearfix" style="padding-left:10px;">
        <div style="float:left; font-weight: 600; padding-top: 6px;">Del:</div>
        <div>
          <!--
              <a href="#" class="social-icon si-borderless si-mail" data-toggle="modal" data-target="#tellafriend-mail">
                <i class="icon-mail"></i>
                <i class="icon-mail"></i>
              </a>
              -->
              <!--  <a href="#" class="social-icon si-borderless si-mail" data-toggle="modal" data-target="#tellafriend-sms">
                    <i class="icon-phone"></i>
                    <i class="icon-phone"></i>
              </a> -->
              <a target="_NEW" id="facebooksharelink" href="https://www.facebook.com/sharer/sharer.php?u=" class="social-icon si-borderless si-facebook">
                <i class="icon-facebook"></i>
                <i class="icon-facebook"></i>
              </a>
              <a target="_NEW" id="twittersharelink" href="https://twitter.com/home?status=" class="social-icon si-borderless si-twitter">
                <i class="icon-twitter"></i>
                <i class="icon-twitter"></i>
              </a>
              <a target="_NEW" id="pinterestsharelink" href="https://pinterest.com/pin/create/button/?url=" class="social-icon si-borderless si-pinterest">
                <i class="icon-pinterest"></i>
                <i class="icon-pinterest"></i>
              </a>
              <a target="_NEW" id="googlesharelink" href="https://plus.google.com/share?url=" class="social-icon si-borderless si-gplus">
                <i class="icon-gplus"></i>
                <i class="icon-gplus"></i>
              </a>
        </div>
      </div>

      <div class="col-md-5 col-sm-5 col-xs-12 apartmentype-features col_last"
           v-if="apartmentAdminPage[0].apartment_file.length">
        <h4>Ekstra materiale</h4>
        <ul>
          <li v-for="file in apartmentAdminPage[0].apartment_file" :key="file">
            <a target="_NEW" :href="file.file[0].url">{{ file.fileName }}</a>
          </li>
        </ul>
      </div>
      
      <div class="col-md-5 col-sm-5 col-xs-12 apartmentype-features col_last" ref="apartmentFiles" style="display:none;">
        <h4>Specifikt i forhold til lejlighed</h4>
        <ul>
          <li v-for="file in apartmentfiles" :key="file">
            <a target="_NEW" :href="file.file">{{ file.filename }}</a>
          </li>
      </ul>
      </div>
      
      <div class="row" style="text-align:center; display:none;" ref="visualview">
        <div class="col-md-12" style="align:center;"><h5>Se en VR eller 360 udgave</h5></div>
        <div class="col-md-6 col-sm-6 col-xs-6 topmargin-sm bottommargin-sm" style="display:none;" ref="vrview">
          <a ref="vrview_link" href="#" target="_NEW" style="color:#222;">
            <img src="/uploads/default/vr-icon.svg" style="width:65px;"><div><b>Virtual reality</b></div>
          </a>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 topmargin-sm bottommargin-sm" style="display:none;" ref="view360">
          <a ref="view360_link" href="#" target="_NEW" style="color:#222;">
            <img src="/uploads/default/360-icon.svg" style="width:100px;"><div><b>360&ordm; visning</b></div>
          </a>
        </div>
      </div>
    </div>

    <div class="col-md-5 col-sm-5 col-xs-12 apartmentype-features apartmentype-features-full col_last nobottommargin">
      <div class="apartment-gallery">
        <carousel :perPage="1"
                  :navigationEnabled="true"
                  :autoplay="false"
                  :paginationEnabled="false"
                  :navigation-next-label="navigationNext"
                  :navigation-prev-label="navigationPrev"
                  :loop="true">
          <slide v-for="(mediaFile, rowId) in apartmentMedia" :key="mediaFile">
            <div v-if="mediaFile.mediaType == 'vimeoVideo'">
              <div class="embed-container">
                <iframe :src="'https://player.vimeo.com/video/'+ mediaFile.vimeoId +'?quality=auto&autoplay=0&loop=0&autopause=0'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                <a class="play-icon video" data-gallery="gallery" :key="0" @click="index = rowId"></a>
              </div>
            </div>
            <div v-if="mediaFile.mediaType == 'image'">
              <div class="images-wrapper">
                <img :src="mediaFile.image">
                <a class="image image-zoom" data-gallery="gallery" :key="0" @click="index = rowId"></a>
              </div>
            </div>         
          </slide>
        </carousel>
      </div>
      <p v-html="apartmentDescription"></p>
      <div class="features">
        <div v-if="apartmentFloor" style='font-weight: 700; padding-left:10px;'>
          Etage
          <div style='font-weight: 300; margin-bottom: 4px;'>
            {{ apartmentFloor }}
          </div>
        </div>
        <div v-if="apartmentM2" style='font-weight: 700; padding-left:10px;'>Areal
          <div style='font-weight: 300; margin-bottom: 4px;'>
            {{ apartmentM2 }}
          </div>
        </div>
        <div v-if="apartmentAmountOfRooms" style='font-weight: 700; padding-left:10px;'>Antal værelser
          <div style='font-weight: 300; margin-bottom: 4px;'>
            {{ apartmentAmountOfRooms }}
          </div>
      </div>
        
        <div v-for="feature in apartmentFeatures"
             :key="feature" style='font-weight: 700; padding-left:10px;'>{{ feature.name }}
          <div style='font-weight: 300; margin-bottom: 4px;'>
            {{ feature.value }}
          </div>
        </div>
    </div>
      
    </div>
    <div class="">
      <div class="owl-carousel owl-theme"></div>
   </div>
    <div style="clear:both;"></div>
    <CoolLightBox :items="imageItems"
                  :index="index"
                  @close="index = null">
    </CoolLightBox>
    
  </div>  
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel'
  import CoolLightBox from 'vue-cool-lightbox'

  export default {
      name: 'Apartmenttypes-grid',
      props: ['apartments', 'apartmentAdminPage'],
      components: {
          Carousel,
          Slide,
          CoolLightBox
  },
  computed: {
  navigationNext: function() { return `<i class="fas fa-chevron-right"></i>` },
  navigationPrev: function() { return `<i class="fas fa-chevron-left"></i>` },
  },
      data() {
          return {
              index: null,
              imageItems: [],
              apartmentfiles: [],
              apartmentMedia: [],
              apartmentHeadline: '',
              apartmentDescription: 'Vælg en lejlighed i oversigten og få vist detaljerne',
              apartmentFloor: '',
              apartmentM2: '',
              apartmentAmountOfRooms: '',
              apartmentFeatures: []
          }
      },
      mounted() {
          if(window.location.hash.substring(1).length > 0) {
              this.loadApartment(window.location.hash.substring(0))
          } else {
            this.updateInfo(null,'0')
          }
      },
      methods: {         
         loadApartment(slug) {
              var ready = false
              if(this.apartments.length > 0) {
                  for(let i=0; this.apartments.length; i++) {
                      if(this.apartments[i].slug == slug) {
                          this.updateInfo(null,String(i))
                      }
                  }
                  ready=true
             }
             if(!ready) {
                this.loadApartment(slug)
             }
          },
          updateInfo: function(event,id) {
              if(id) {
                  apartmentId = id
              } else {
                  var apartmentId = event.target.offsetParent.id
              }
              this.$refs.visualview.style.display = 'none'
              this.$refs.view360.style.display = 'none'
              this.$refs.vrview.style.display = 'none'
              this.$refs.apartmentFiles.style.display = 'none'

              if(this.apartments[apartmentId].link_360 || this.apartments[apartmentId].vr_link) {
                 this.$refs.visualview.style.display = 'block'
                 if(this.apartments[apartmentId].link_360) {
                     this.$refs.view360.style.display = 'block'
                     this.$refs.view360_link.href = this.apartments[apartmentId].link_360
                 }
                 if(this.apartments[apartmentId].vr_link) {
                     this.$refs.vrview.style.display = 'block'
                     this.$refs.vrview_link.href = this.apartments[apartmentId].vr_link
                 }
              }
              if(this.apartments[apartmentId].media.length > 0 ) {
                  this.apartmentMedia = []
                  this.imageItems = []
                  for(let i=0; i < this.apartments[apartmentId].media.length; i++) {
                      if(this.apartments[apartmentId].media[i].image.length > 0) {
                          var image = this.apartments[apartmentId].media[i].image[0].url
                      }
                      let feature = {
                          mediaType: this.apartments[apartmentId].media[i].mediatype,
                          vimeoId: this.apartments[apartmentId].media[i].vimeoId,
                          image: image    
                      }
                      if(this.apartments[apartmentId].media[i].mediatype == 'vimeoVideo') {
                          var src = 'https://player.vimeo.com/video/'
                                         + this.apartments[apartmentId].media[i].vimeoId
                                         +'?quality=auto&autoplay=0&loop=0&autopause=0'
                      } else {
                          src = image
                      }
                      let lightboxItems = {
                          src: src
                      }
                      this.imageItems[i] = lightboxItems
                      this.apartmentMedia.push(feature)
                  }
              }

              if(this.apartments[apartmentId].attached_files.length > 0 ) {
                 this.$refs.apartmentFiles.style.display = 'block'
                 this.apartmentfiles = []
                 for(let i=0; this.apartments[apartmentId].attached_files.length; i++) {
                     let file = {
                         file: this.apartments[apartmentId].attached_files[i].file[0].url,
                         filename: this.apartments[apartmentId].attached_files[i].filename
                     }
                     this.apartmentfiles.push(file)
                 }
             }
             if(this.apartments[apartmentId].title) {
                 this.apartmentHeadline = this.apartments[apartmentId].title
             }
             if(this.apartments[apartmentId].description) {
                 this.apartmentDescription = this.apartments[apartmentId].description
             }
             this.apartmentFloor = this.apartments[apartmentId].floor
             this.apartmentM2 = this.apartments[apartmentId].size
             this.apartmentAmountOfRooms = this.apartments[apartmentId].amount_of_rooms
             window.location.hash = this.apartments[apartmentId].slug
             if(this.apartments[apartmentId].features.length > 0 ) {
                 this.apartmentFeatures = []
                 for(let i=0; i < this.apartments[apartmentId].features.length; i++) {
                     if(this.apartments[apartmentId].features[i].feature.length > 0 &&
                        this.apartments[apartmentId].features[i].headline.length > 0 ) {
                            let feature = {
                                name: this.apartments[apartmentId].features[i].headline,
                                value: this.apartments[apartmentId].features[i].feature
                       }
                       this.apartmentFeatures.push(feature)
                     }
                     
                 }
             }
          }
      }
  }
</script>

<style>
</style>
