
<script>
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
 

  export default {
    name: 'ContactMeModal',
    props: ['headline','selectedApartment'],
    data() {
    return {
      isLoading:false,
      fullPage: true,
      loader: 'dots',
      loadingColor: '#f89520',
      loadingOpacity: 0.5,
      firstName: '',
      email: '',
      cellphone: '',
      hvorHarDuHortOmHplHusene: '',
      errormessage: 'Feltet skal udfyldes',
      errors: [],
      submitSuccess: false,
      submitErrors: false,
   }
  },
  components: {
      Loading
  },
    methods: {
      close() {
      this.$emit('close');
      this.submitSuccess = false
      this.submitErrors = false
  },
        getErrors: function (property) {
      const errors = [];
      if (!this.errors.length) {
          return errors;
        }
        for (let i = 0; i < this.errors.length; i++) {
          if (this.errors[i].key === property) {
            errors.push(this.errors[i].message);
          }
        }
        return errors;
      },
      validate: function (property) {
        if (!property) {
          this.errors = [];
        } else {
          this.errors = this.errors.filter((item) => item.key !== property);
        }
        if ((!property || property === 'firstName') && !this.firstName) {
          this.errors.push({ key: 'firstName', message: this.errormessage });
        }
        if ((!property || property === 'email') && !this.email) {
          this.errors.push({ key: 'email', message: this.errormessage });
        }
        if ((!property || property === 'cellphone') && !this.cellphone) {
          this.errors.push({ key: 'cellphone', message: this.errormessage });
        }

      },
      onSubmit: function (evt) {
        this.validate();
        if (this.errors.length) {
         evt.stopPropagation();
         evt.preventDefault();
          return false;
        }
        this.isLoading = true;
        const form = evt.target;
        const data = new FormData(form);
        const request = new XMLHttpRequest();

        // Add Honeypot field
        data.append('freeform_form_handle', '');

        data.append('freeform_payload', window.contactOverlayFormPayload);
        data.append(window.csrfTokenName,window.csrfTokenValue);
    
        // Add action which handles freeform validation
        data.append('action', 'freeform/submit');

        // Add the form's hash, so that it gets recognized by the form service
        data.append('formHash', window.contactOverlayFormHash);
        // Append the data
        data.append('firstName', this.firstName);
        data.append('email', this.email);
        data.append('cellphone', this.cellphone);
        data.append('hvorHarDuHortOmHplHusene', this.hvorHarDuHortOmHplHusene);
        data.append('selectedApartment', this.selectedApartment);
    
        // Create a request
        request.open('POST', 'https://hpl-husene.dk/', true);
        request.setRequestHeader("Cache-Control", "no-cache");

        // Add the AJAX headers specific to Craft CMS
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

        // Attach the response handler callback
        request.onload = () => {

        this.isLoading = false
        var response = []
        if (request.status === 200) {
            response = JSON.parse(request.response);
            const { success, finished, errors, formErrors } = response
            if (success && finished) {
              // Reset the form so that the user may enter fresh information
              form.reset();
              this.submitSuccess = true;
            } else if (errors || formErrors) {
              this.submitErrors = true;
            }
         } else {
            this.submitErrors = true;
         }
        };

        // Send the request
        request.send(data);

        evt.stopPropagation();
        evt.preventDefault();
        return false;
      },
      formSubmitSuccess() {

      }
    },
  };
</script>
<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal modal-dialog-centered"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
          <slot name="header">
            <h2>{{ headline }}</h2>

            <button
              type="button"
              class="close"
              @click="close"
              aria-label="Close modal"
            >
              <i class="fa fa-times"></i>
            </button>
          </slot>
        </header>
        <section class="modal-body">
          <div v-if="submitSuccess" class="col-md-12 col-xl-12 col-xs-12 center" style="font-size: 40px;">Mange tak for din henvendelse.<BR/> Du vil hører fra os</div>
          <div class="alert alert-danger" v-if="submitErrors">Der er opstået en fejl. Prøv venligst igen, eller kontakt mægler direkte</div>
          <form v-if="!submitSuccess" v-on:submit="onSubmit">
              <div class="form-group col-sm-12 col-xs-12">
                <label for="form-input-firstName" class="required">Navn</label>
                <input class="form-control" v-model="firstName" name="firstName" type="text" id="form-input-firstName" placeholder="Indtast navn">
                <ul v-if="getErrors('firstName').length" class="errors">
                  <li v-for="error in getErrors('firstName')" :key="error">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div class="form-group col-sm-12 col-xs-12">
                <label for="form-input-email" class="required">Email</label>
                <input class="form-control" v-model="email" name="email" type="email" id="form-input-email" placeholder="Indtast email">
                <ul v-if="getErrors('email').length" class="errors">
                  <li v-for="error in getErrors('email')" :key="error">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div class="form-group col-sm-12 col-xs-12">
                <label for="form-input-cellphone" class="required">Telefon</label>
                <input class="form-control" v-model="cellphone" name="cellphone" type="text" id="form-input-cellphone" placeholder="Indtast telefonnummer">
                <ul v-if="getErrors('cellphone').length" class="errors">
                  <li v-for="error in getErrors('cellphone')" :key="error">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div class="form-group col-sm-12 col-xs-12">
                <label>Den valgte lejlighed</label>
                <p>{{ selectedApartment }}</p>
              </div>
              <div class="form-group col-sm-12 col-xs-12">
                <label for="form-input-hvorHarDuHortOmHplHusene" class="required">Hvor har du hørt om HPL-husene</label>
                <select class="form-control" v-model="hvorHarDuHortOmHplHusene" name="hvorHarDuHortOmHplHusene" id="form-input-hvorHarDuHortOmHplHusene">
                  <option value="Vælg">Vælg</option>
                  <option value="facebook">Facebook</option>
                  <option value="instagram">Instagram</option>
                  <option value="linkedin">LinkedIn</option>
                  <option value="boligportalen">Boligportalen</option>
                  <option value="sogtPaNettet">Søgt på nettet</option>
                  <option value="googleAnnonce">Google Annonce</option>
                  <option value="maegler">Mægler</option>
                  <option value="avis">Avis</option>
                  <option value="andet">Andet</option>
                </select>                  
              </div>
              <div class="form-group col-sm-12 col-xs-12 submit-align-left">
                <button class="btn btn-default button" type="submit" name="form_page_submit">Bliv kontaktet</button>
              </div>
          </form>
        </section>
      </div>
      <Loading :active.sync="isLoading"
               :can-cancel="true"
               :is-full-page="fullPage"
               :color="loadingColor"
               ></Loading>
      
    </div>
  </transition>
</template>
<style>
  .modal-backdrop {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    width: 800px;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
  display: flex;
  position:fixed;
  flex-direction: column;
    left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
  color: #000;
  font-size: 14px;
  font-weight: 900;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }
  ul.errors {
  padding-left:15px !important;
  }
</style>  
