import gql from 'graphql-tag'

export const GET_SLIDER_QUERY = gql`
query {
  entries (section:"slider") {
  ... on slider_slider_Entry {
      button1_link,
      button2_link,
      button1_text,
      button2_text,
      link_1_destination,
      link_2_destination,
      mediaType,
      overlayColor,
      overlayTransparency,
      headline,
      headlineResponsive,
      slide_image {
        url
      },
      sub_headline,
      sub_headlineResponsive,
      vimeoId,
      youtubeId
    }
  }
}`
