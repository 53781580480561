 <template>
    <section id="content">
      <div class="content-wrap-frontpage">
        <div v-for="global in globalSet" :key="global">
        <a :href="global.callToActionLinkOnFrontpage" class="button button-full button-dark center tright header-stick">
          <div class="container clearfix">
            {{ global.callToActionTextFrontpage }}
          </div>
        </a>
        </div>

        <div class="clear"></div>
        <div v-for="contentrow in contentrows" :key="contentrow">
          <div class="container clearfix" v-for="row in contentrow.contentgrid" :key="row">

            <div v-if="row.medie_position === 'right'" :class="getopsitewith(row.medie_width) + ' topmargin-sm bottommargin-sm'">
              <div class="heading-block">
                <h2>{{ row.headline }}</h2>

                <div class="hidden-md hidden-lg mediaContentMargin">
                  <div v-for="image in row.image" :key="image">
                    <img v-if="image.url && row.medie_type === 'image'"
                         class="fadeInRightBig animated"
                         data-animate="fadeInRightBig"
                         alt=""
                         v-lazy="image.url">
                  </div>
                  
                  <div style="clear:both;"></div>
                  
                  <div v-if="row.medie_type === 'video'">
                    <div class="embed-container"
                         v-if="row.video_type === '' || row.video_type === 'youtube'">
                      <iframe :src="'https://www.youtube.com/embed/'+ row.youtube_video  +'?rel=0'"
                              frameborder="0"
                              allowfullscreen></iframe>
                    </div>
                    
                    <div class='embed-container'
                         v-if="row.video_type === 'vimeo'">
                      <iframe :src="'https://player.vimeo.com/video/' + row.vimeo_video"
                              frameborder='0'
                              webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </div>
                  </div>
                </div>
                
                
                <span v-html="row.text"></span>
                <a v-if="row.url_link" :href="row.url_link" class="button button-border button-rounded button-large noleftmargin topmargin-sm">{{ row.link_text }}</a>
              </div>
            </div>
            
            
            <div v-if="row.medie_position !== 'none' || !row.text" class="hidden-sm hidden-xs">
              <div :class="mediapos(row.text,row.medie_width,row.medie_position) + ' topmargin-sm bottommargin'">     
                <div v-for="image in row.image" :key="image">
                  <img v-if="image.url && row.medie_type === 'image'"
                       class="fadeInRightBig animated"
                       data-animate="fadeInRightBig"
                       alt=""
                       v-lazy="image.url">
                </div>
                
                <div style="clear:both;"></div>
                
                <div v-if="row.medie_type === 'video'">
                  <div class="embed-container"
                       v-if="row.video_type === '' || row.video_type === 'youtube'">
                    <iframe :src="'https://www.youtube.com/embed/'+ row.youtube_video  +'?rel=0'"
                            frameborder="0"
                            allowfullscreen></iframe>
                  </div>
                  
                  <div class='embed-container'
                       v-if="row.video_type === 'vimeo'">
                    <iframe :src="'https://player.vimeo.com/video/' + row.vimeo_video"
                            frameborder='0'
                            webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                  </div>
                </div>
                <a v-if="row.url_link && row.medie_position === 'right'"
                   :href="row.url_link"
                   class="button button-border
                          button-rounded hidden-lg 
                          hidden-md button-large 
                          noleftmargin topmargin-sm">{{ row.link_text }}</a>
                  
              </div>
            </div>
              <div v-if="row.text && row.medie_position === 'left'"
                   :class="getopsitewith(row.medie_width) +' topmargin-sm bottommargin-sm col_last'">
                <div class="heading-block">
                  <h2>{{ row.headline }}</h2>

                <div class="hidden-md hidden-lg mediaContentMargin">
                  <div v-for="image in row.image" :key="image">
                    <img v-if="image.url && row.medie_type === 'image'"
                         class="fadeInRightBig animated"
                         data-animate="fadeInRightBig"
                         alt=""
                         v-lazy="image.url">
                  </div>
                  
                  <div style="clear:both;"></div>
                  
                  <div v-if="row.medie_type === 'video'">
                    <div class="embed-container"
                         v-if="row.video_type === '' || row.video_type === 'youtube'">
                      <iframe :src="'https://www.youtube.com/embed/'+ row.youtube_video  +'?rel=0'"
                              frameborder="0"
                              allowfullscreen></iframe>
                    </div>
                    
                    <div class='embed-container'
                         v-if="row.video_type === 'vimeo'">
                      <iframe :src="'https://player.vimeo.com/video/' + row.vimeo_video"
                              frameborder='0'
                              webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </div>
                  </div>
                  <a v-if="row.url_link && row.medie_position === 'right'"
                     :href="row.url_link"
                     class="button button-border
                            button-rounded hidden-lg 
                            hidden-md button-large 
                            noleftmargin topmargin-sm">{{ row.link_text }}</a>
                </div>

                  
                  <span v-html="row.text">
                  </span>
                  <a v-if="row.url_link"
                     :href="row.url_link"
                     class="button button-border
                            button-rounded button-large 
                            noleftmargin topmargin-sm">{{ row.link_text }}</a>
                </div>
              </div>
                
              <div v-if="row.text && row.medie_position === 'none'"
                   class="topmargin-sm bottommargin-sm">
                <div class="heading-block">
                  <h2>{{ row.headline }}</h2>
                  <span v-html="row.text"></span>
                  <a v-if="row.url_link" :href="row.url_link"
                     lass="button button-border
                           button-rounded button-large 
                           noleftmargin topmargin-sm">{{ row.link_text }}</a>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>
 </template>

<script>
export default {
   name: 'Contentrows',
   props: ['contentrows', 'globalSet'],
   methods: {
      mediapos (text,width,position) {
         var output1 = ''
         var output2 = ''
         if(text == '') {
            output1 = 'col_' + width + ' '
         } else {
            output1 = 'col_' + width + ' '
         }
         if(position == 'right') {
            output2 = 'col_last '
         }
         return output1 + output2
      },
      getopsitewith (width) {
         switch(width) {
            case 'half':
               return 'col_half'
            case 'one_third':
               return 'col_two_third'
            case 'two_third':
               return 'col_one_third'
            case 'one_forth':
               return 'col_three_forth'
            case 'three_forth':
               return 'col_one_forth'
            case 'one_fifth':
               return 'col_four_fifth'
            case 'two_fifth':
               return 'col_three_fifth'
            case 'three_fifth':
               return 'col_two_fifth'
            case 'four_fifth':
               return 'col_one_fifth'
            case 'one_sixth':
               return 'col_five_sixth'
            case 'five_sixth':
               return 'col_one_sixth'
            default:
               return 'col_full'
         }
      }
   }
}
</script>

<style>
</style>
