<template>
<header id="header" :class="'full-header transparent-header ' + stickyheader + ' ' + fullSizeClass">
  <aside v-if="popupCallToAction" :class="active + ' sidebar-mobile'">
    <div class="sidebar-wrapper">
      <a class="box">
	<div class="text"><span>{{ popupCallToActionHeadline }}</span></div>
	<div :class="'icon '+ active" v-on:click="contactformpopup">
          <i class="far fa-arrow-alt-circle-up"></i>
          <div class="close"><i class="far fa-arrow-alt-circle-down"></i></div>
	</div>
      </a>
      <div class="formular">
	<div class="wrapper">
          <img :src="popupCallToActionImage" :alt="popupCallToActionImageTitle">
          <p v-html="popupCallToActionText"></p>
          <Contactform v-bind:gaevent="'mobile'"/>
	</div>
      </div>
    </div>
  </aside>
  <a href="/kontakt">
      <aside v-show="contactlabel" class="stickey-label hidden-xs" style="display:none;">
        <span class="text">Udfyld kontaktformular</span>
      </aside>
  </a>

  <div id="mobile-dark-fix" class="not-dark">
    <div id="header-wrap">
      <div class="container clearfix">
        <div id="primary-menu-trigger" v-on:click="openhamburger"><i :class="fabar + ' menu-icon fas'"></i></div>
        
        <div v-if="whitelogo">
          <div id="logo" v-for="global in globalSet" :key="global">
            <router-link to="/">
            <div class="standard-logo" :alt="global.logo_alt" v-for="logo in global.big_logo" v-bind:key="logo">
              <img :src="logo.url" :alt="global.logo_alt">
            </div>
            </router-link>
          </div>
        </div>
        
        <div v-if="colorlogo">
          <div id="logo" v-for="global in globalSet" :key="global">
            <a href="/" class="standard-logo " :alt="global.logo_alt" v-for="logo in global.small_logo" v-bind:key="logo">
              <img :src="logo.url" :alt="global.logo_alt">
            </a>
          </div>
        </div>
        
        <nav id="primary-menu" class="not-dark" v-show="hbmenu"> 
          <ul v-show="hbmenu">
            <li class="clearfix">
              <router-link to="/leje-lejligheder-studieboliger-odense-city-campus">
                <div>HPL-HUSENE</div>
              </router-link>
            </li>
            <li class="clearfix">
              <router-link to="/ledige-lejligheder">
                <div>Ledige Lejligheder</div>
              </router-link>
            </li>	    
            <li class="clearfix">
              <router-link to="/leje-lejligheder-studieboliger-odense-oversigt">
                <div>Oversigt</div>
              </router-link>
            </li>
            <li class="clearfix submenu">
              <router-link to="/boligtyper"
                           @mouseover.native="dropdown"
                           @mouseout.native="closedropdown"
                           @click.native.capture="triggerdropdown">
                <div>Boligtyper</div>
              </router-link>
              <transition name="fade">
              <ul class="dropdown-menu" v-if="show">
                <li class="clearfix">
                  <router-link to="/lejlighed-type-a-odense-city"
                               @mouseover.native="dropdown"
                               @mouseout.native="closedropdown">
                    <div>Lejlighed type A</div>
                  </router-link>
                </li>
                <li class="clearfix">
                  <router-link to="/lejlighed-type-c-odense-city"
                               @mouseover.native="dropdown"
                               @mouseout.native="closedropdown">
                    <div>Lejlighed type C</div>
                  </router-link>
                </li>               
              </ul>
              </transition>
            </li>
            <li class="clearfix">
              <router-link to="/galleri">
                <div>Galleri</div>
              </router-link>
            </li>
            <li class="clearfix">
              <router-link to="/odense-studieby-city-campus">
                <div>Studieby</div>
              </router-link>
            </li>
            <li class="clearfix submenu">
              <router-link to="/kontakt"
                           @mouseover.native="dropdown2"
                           @mouseout.native="closedropdown2"
                           @click.native.capture="triggerdropdown2">
                <div>Kontakt</div>
              </router-link>
              <transition name="fade">
              <ul class="dropdown-menu" v-if="show2">
                <li class="clearfix">
                  <router-link to="/beboermappe"
                               @mouseover.native="dropdown2"
                               @mouseout.native="closedropdown2">
                    <div>Beboermappe</div>
                  </router-link>
                </li>
              </ul>
              </transition>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>  
</template>

<script>
  import Contactform from './Contactform'
  export default {
    name: 'header',
    props: ['globalSet', 'fullsizepage'],
    data() {
      return {
        clickCount: 0,
        active: '',
        stickyheader:'',
        show: false,
        show2: false,
        colorlogo:'',
        whitelogo:'',
        hbmenu: false,
        fabar: "fa-bars",
        popupCallToAction: false,
        popupCallToActionHeadline: '',
        popupCallToActionText: '',
        popupCallToActionImage: '',
        popupCallToActionImageTitle: '',
        contactlabel: false,
        contactformActive: false,
        fullSizeClass:''
      }
    },
    components: {
        Contactform
    },
    created() {
        window.addEventListener('resize', this.responsiveHandler)
    },
    destroyed() {
        window.removeEventListener('resize', this.responsiveHandler)
    },
    watch: {
       globalSet: function() {
          this.popupCallToAction = this.globalSet[0].popupCallToAction
          this.popupCallToActionHeadline = this.globalSet[0].popupCallToActionHeadline
          this.popupCallToActionText = this.globalSet[0].popupCallToActionText
          this.popupCallToActionImage = this.globalSet[0].popupCallToActionImage[0].url
          this.popupCallTOActionImageTitle = this.globalSet[0].popupCallToActionImage[0].title
       },
       $route () {
           this.responsiveHandler()
       }
    },
    mounted() {
      this.responsiveHandler()
      setTimeout(() => {
          this.contactlabel = true
      }, 2000)
    },
    methods: {
       dropdown() {
            if(window.innerWidth > 991) {
                this.show = true
            }
       },
       closedropdown() {
           if(window.innerWidth > 991) {
               this.show = false
           }
       },
       triggerdropdown: function(event) {
          if(window.innerWidth < 991) {
              this.clickCount++
              if(this.clickCount == 1) {
                  this.show = true
                  event.preventDefault()
              } else {
                  this.clickCount = 0                              
              }
         }
       },
       dropdown2() {
            if(window.innerWidth > 991) {
                this.show2 = true
            }
       },
       closedropdown2() {
           if(window.innerWidth > 991) {
               this.show2 = false
           }
       },
       triggerdropdown2: function(event) {
          if(window.innerWidth < 991) {
              this.clickCount++
              if(this.clickCount == 1) {
                  this.show2 = true
                  event.preventDefault()
              } else {
                  this.clickCount = 0                              
              }
         }
       },

       openhamburger: function() {
           this.hbmenu = !this.hbmenu;
           if(this.hbmenu) {
               this.fabar = 'fa-times';
           } else {
               this.fabar = 'fa-bars';
           }
       },
       contactformpopup: function() {
          this.contactformActive = !this.contactformActive
          if(this.contactformActive) {
              this.active = 'active'
          } else {
              this.active = ''
          }
       },
       responsiveHandler () {
           if(this.fullsizepage) {
               this.fullSizeClass = 'fullsize'
           } else {
               this.fullSizeClass = ''
           }
  
           if(window.innerWidth < 991) {
              this.hbmenu = false
              this.fabar = 'fa-bars'
              this.show = false
              this.clickCount = 0                    
              this.fullSizeClass = ''            
              this.colorlogo = true
              this.whitelogo = false                   
           } else {
              this.nonResponsiveMode()
              this.hbmenu = true 
           }
       },
       nonResponsiveMode () {                                  
          if(this.fullsizepage) {                        
              this.colorlogo = false
              this.whitelogo = true
          } else {
              this.colorlogo = true
              this.whitelogo = false
          }
          window.document.onscroll = () => {
             let header = document.getElementById('header');
             if(window.scrollY > header.offsetTop){
                this.stickyheader = 'sticky-header'
                this.colorlogo = true
                this.whitelogo = false
             } else {
                this.stickyheader = ''
                    if(this.fullsizepage) {
                        this.whitelogo = true
                        this.colorlogo = false
                    } else {
                        this.whitelogo = false
                        this.colorlogo = true
                    }
             }
         }
       }
    }
  }

</script>

<style>
</style>
