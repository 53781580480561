<template>
  <div id="app" class="small-container">
    <div id="wrapper" class="clearfix">
      <headerElement v-bind:globalSet="globalSets"
                     v-bind:fullsizepage="fullsize"/>
      <router-view v-bind:globalSet="globalSets"/>
    </div>
    <footerElement v-bind:globalSet="globalSets"/>
  </div>  
</template>

<script>
import HeaderElement from './components/Header.vue'
import FooterElement from './components/Footer.vue'
import { GET_ALL_GLOBALS } from './graphql/globalsetQueries'
import { GET_SEOMATIC_TAGS } from './graphql/seomaticQueries'

export default {
    name: 'Layout',
    components: {
      'headerElement': HeaderElement,
      'footerElement': FooterElement
    },
    data() {
      return {
          globalSets: [],
          fullsize: false
      }
    },
    apollo: {
      globalSets: GET_ALL_GLOBALS
    },
    created() {
      this.setFullSize()
      this.fetchData()
    },
    watch: {
       globalSets() {
           document.getElementById('favicon').href = this.globalSets[0].favicon[0].url
       },
       $route () {
           this.setFullSize()
           this.fetchData()
           this.$gtag.pageview({
               page_path: window.location.pathname
           })
       }
    },
    methods: {
      setFullSize () {
          if( window.location.pathname == '/' ||
              window.location.pathname == '/lejlighed-type-a-odense-city' ||
              window.location.pathname == '/lejlighed-type-c-odense-city' ||
              window.location.pathname == '/leje-lejligheder-studieboliger-odense-oversigt' ||
              window.location.pathname == '/seebladsgade-lejligheder-odense-city-campuse' ||
              window.location.pathname == '/odense-studieby-city-campus') {
              this.fullsize = true;
           } else {
              this.fullsize = false
           }
      },
      fetchData () {
        this.$apollo.query({
          query: GET_SEOMATIC_TAGS,
            variables: {
               uri: window.location.pathname
            }
           }).then(function (response) {
               document.title = response.data.seomatic.metaTitleContainer.replace(/<[^>]*>?/gm, '')
                //var script = JSON.parse(response.data.seomatic.metaScriptContainer)
                //document.head.append(script.script)
                document.head.append(response.data.seomatic.metaTagContainer)
                document.head.append(response.data.seomatic.metaLinkContainer)
        })
      }
    }
};
</script>


<style>
  @import './assets/css/bootstrap.css';
  @import './assets/css/default.css';
  @import './assets/css/dark.css';
  @import './assets/css/font-icons.css';
  @import './assets/css/responsive.css';
  @import './assets/css/custom.css';
  @import './assets/css/all.css';
</style>
