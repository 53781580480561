<template>
  <div>
    <section id="page-title">
      <div class="container clearfix">
        <h1 class="building-info" style="float:left;">{{ building.title }}</h1>
        <span class="goback" style="float:right; margin-top:0px;">
          <router-link to="/leje-lejligheder-studieboliger-odense-oversigt">
            <b>Tilbage til oversigten</b>
          </router-link>
        </span>
      </div>
    </section>
    
    <section id="content">
      <div class="content-wrap">
        <div :class="'container ' + building.facadeBilledeBredde + ' clearfix'">
          <div class="col_half pull-left nomargin hidden-xs hidden-sm">
            {{ building.extra_information }}
          </div>
          <div id="lejlighed-label" class="col_half clearfix nobottommargin pull-right">
            <div v-if="stateRent" class="fright">
              <p class="nobottommargin center">Til leje</p>
              <p class="nobottommargin status" status-type="rent" status-availability="free"></p>
            </div>
            <div v-if="stateSales" class="fright">
              <p class="nobottommargin center">Til salg</p>
              <p class="nobottommargin status" status-type="buy" status-availability="free"></p>
            </div>
            
            <div v-if="stateOnhold" class="fright">
              <p class="nobottommargin center">Reseveret</p>
              <p class="nobottommargin status" status-availability="reserved"></p>
            </div>
            
            <div v-if="stateRentAndSold" class="fright">
              <p class="nobottommargin center">Udlejet</p>
               <p class="nobottommargin status" status-type="rent" status-availability="occupied"></p>
            </div>
            
            <div v-if="stateSaleAndSoled" class="fright">
              <p class="nobottommargin center">Solgt</p>
              <p class="nobottommargin status" status-type="buy" status-availability="occupied"></p>
            </div>
            
            <div v-if="stateSupply" class="fright">
              <p class="nobottommargin center">August 2019</p>
              <p class="nobottommargin status" status-type="buy" status-availability="supply"></p>
            </div>
          </div>
          <div class="col_half pull-left nomargin hidden-md hidden-lg">
            {{ building.extra_information }}
          </div>
         </div>
        <div :class="'container buttommargin ' + building.facadeBilledeBredde">
          <div v-if="building.associatedBuildings.length > 0"
               id="buildingSelector"
               class="col-md-3 hidden-xs hidden-sm">
            <h3>Skift facade</h3>
            <div id="buildingViewSelect" class="slider single-item">              
              <div v-for="associatedBuilding in building.associatedBuildings" :key="associatedBuilding">
                <router-link :to="'/' + associatedBuilding.uri">
                  <img :alt="associatedBuilding.facade[0].title"
                       :src="associatedBuilding.facade[0].url">
                </router-link>
                <span class="buildingName">{{ associatedBuilding.title }}</span>
              </div>
            </div>
          </div>
          <div id="lejlighed-container"
               :class="associatedBuildingsEnabled? 'col-md-9 hidden-xs hidden-sm' : 'col_full hidden-xs hidden-sm'">
            <img v-if="building.facade[0].url"
                 :alt="building.facade[0].title"
                 :src="building.facade[0].url">
            <div v-for="(property, index) in properties"
                 :key="property"
                 :style="property.debug? 'background: #7d7d7d;' : '' + ' top:' + property.coordinate_y + '%; left:' + property.coordinate_x +'%; height:' + property.height +'%;' + ' width:' + property.width + '%;'">
              <span class="status hidden-xs hidden-sm"
                    :status-type="getStatus(property.lejligheds_type)"
                    :status-availability="getAvailability(property.lejlighed_status)"></span>
              <div class="info" :onMouseOver="'this.style.backgroundColor=\'' + property.marking_color+ '\''" onMouseOut="this.style.backgroundColor='transparent'" style="width: 100%; height: 100%; opacity: 0.6;" @click="updatePropertyInfo" :id="index" v-scroll-to="'#lejlighed-billede'">
              </div>
            </div>
          </div>
        </div>
        <div :class="'container clearfix residentinfo ' + building.facadeBilledeBredde ">
          <div id="lejlighed-table" class="col_full hidden-md hidden-lg">
            <table class="table">
              <tr>
                <th>Lejlighed</th>
                <th>St&oslash;rrelse</th>
                <th class="text-center">Status</th>
              <tr v-for="(property, index) in properties"
                 :key="property" :id="index" @click="updatePropertyInfo" v-scroll-to="'#lejlighed-billede'">
                <td :id="index">{{ property.bolignr }}</td>
                <td :id="index">{{ property.strelse }}</td>
                <td :id="index" class="status mobile"
                    :status-type="getStatus(property.lejligheds_type)"
                    :status-availability="getAvailability(property.lejlighed_status)"></td>
              </tr>
            </table>
          </div>
          <div id="lejlighed-billede" class="col_two_third">
            <carousel
              :perPage="1"
              :navigationEnabled="true"
              :navigation-next-label="navigationNext"
              :navigation-prev-label="navigationPrev"              
              :paginationEnabled="false">
              <slide v-for="(image, id) in propertyImages" :key="image">
                          <div class="images-wrapper">
                            <img :src="image.url" :alt="image.title" :key="0">
                            <a class="image image-zoom" data-gallery="gallery" :key="0" @click="index = id"></a>
                            </div>
            </slide>
            </carousel>
          </div>
          <div id="lejlighed-info" class="col_one_third col_last">
            <h3>Information</h3>
            <ul class="list-unstyled">             
              <li v-for="propertyInfo in propertyData" :key="propertyInfo">
                <div v-if="propertyInfo.type === 'link'">
                  {{ apartment.name }}: <span class="pull-right">
                    <a :target="propertyInfo.target"
                       :alt="propertyInfo.linkalt"
                       :rel="propertyInfo.linkrel"
                       :href="propertyInfo.link">
                      {{ propertyInfo.value }}
                    </a>
                  </span>                    
                </div>
                <div v-if="propertyInfo.type != 'link'">
                  {{ propertyInfo.name }}: <span class="pull-right">
                    {{ propertyInfo.value }}
                  </span>             
                </div>
              <li>
                <div>Bygnings info:
                  <span class="pull-right">
                    <a href='#' @click="showModal">Læs mere her</a>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          
          <h3 :class="'nobottommargin hidden-xs hidden-sm col_full ' + building.facadeBilledeBredde">
            {{ building.extra_billede_title }}
          </h3>
          <div id="lejlighed-container"
               :class="'col_full hidden-xs hidden-sm ' + building.facadeBilledeBredde">
            <img v-if="building.extra_billede[0]"
                 :alt="building.extra_billede[0].title"
                 :src="building.extra_billede[0].url">
          </div>
          <div style="clear:both;"></div>
          <div class="goback" style="float:right; margin-top:0px; font-size:18px;">
            <router-link to="/leje-lejligheder-studieboliger-odense-oversigt"><b>Tilbage til oversigten</b></router-link>
          </div>
        </div>
      </div>
    </section>
    <CoolLightBox :items="imageItems"
                  :index="index"
                  @close="index = null">
    </CoolLightBox>

    <modal
      v-show="isModalVisible"
      @close="closeModal"
      v-bind:headline="'Bygningsinformation'"
      v-bind:text="building.supplementaryBuildingText"
    /> 
  </div>  
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';
    import { GET_BUILDINGS_QUERY } from '../graphql/buildingQueires'
    import { GET_PROPERTY_QUERY } from '../graphql/buildingQueires'
    import CoolLightBox from 'vue-cool-lightbox'

    import modal from './Modal'

    export default {
    name: 'Buildinginfo',
        data() {
            return {
                imageItems: [],
                index: null,
                building: [],
                properties: [],
                associatedBuildingsEnabled: false,
                isModalVisible: false,
                propertyData: [],
                propertyImages: [],
                stateRent: false,
                stateSales: false,
                stateOnhold: false,
                stateRentAndSold: false,
                stateSaleAndSoled: false,
                stateSupply:false,
           }
    },
      computed: {
  navigationNext: function() { return `<i class="fas fa-chevron-right"></i>` },
  navigationPrev: function() { return `<i class="fas fa-chevron-left"></i>` },
  },

        watch: {
            $route () {
                this.fetchData()
            }
        },
        created() {
            this.fetchData()
        },
        components: {
            modal,
            Carousel,
            Slide,
            CoolLightBox
        },
        methods: {
            goto(url) {
                this.$router.push(url)
            },
            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
            getAvailability (availability) {
                switch(availability) {
                    case 'available':
                        return 'free'
                    case 'onhold':
                        return 'reserved'
                    case 'soled':
                        return 'occupied'
                    case 'supply':
                        return 'supply'
                    default:
                        return 'other'
                }
            },
            getStatus(status) {
                switch(status) {
                    case 'rent':
                        return 'rent'
                    case 'buy':
                        return 'buy'
                    default:
                        return 'other'
                }
            },
            fetchData () {
                this.$apollo.query({
                    query: GET_BUILDINGS_QUERY,
                    variables: {
                        slug: window.location.pathname.replace('/bygning/', '')
                    }
                }).then(function (response) {
                    if(response.data.building.length > 0) {
                        this.building = response.data.building[0]
                    }
                    if(this.building.associatedBuildings.length > 0) {
                        this.associatedBuildingsEnabled = true
                    }
                    if(this.building.id) {
                        this.fetchProperties(this.building.id)
                    }
                }.bind(this))
           },
           fetchProperties ($building) {
                this.$apollo.query({
                    query: GET_PROPERTY_QUERY,
                    variables: {
                        building: $building
                    }
                }).then(function (response) {
                    if(response.data.property.length > 0) {
                        this.properties = response.data.property
                    }
                    for(let i=0; i < this.properties.length; i++) {
                        console.log(this.properties[i])
                        if(this.properties[i].lejligheds_type == 'rent') {
                            this.stateRent = true
                        }
                        if(this.properties[i].lejligheds_type == 'buy' &&
                           this.properties[i].lejlighed_status == 'available') {
                            this.stateBuy = true
                        }
                        if(this.properties[i].lejlighed_status == 'onhold') {
                            this.stateOnhold = true
                        }
                        if(this.properties[i].lejligheds_type == 'rent' &&
                           this.properties[i].lejlighed_status == 'soled') {
                            this.stateRentAndSold = true
                        }
                        if(this.properties[i].lejligheds_type == 'buy' &&
                           this.properties[i].lejlighed_status == 'soled') {
                            this.stateSaleAndSold = true
                        }
                        if(this.properties[i].lejlighed_status == 'supply') {
                            this.stateSupply = true
                        }
                    }
                }.bind(this))
           },
           updatePropertyInfo: function(event) {

               var propertyId = event.target.id
               var propertyData = this.properties[propertyId]
               if(!propertyData.click_disabled) {
                   this.$swal(propertyData.udbudstekst)
               } else {
                   this.propertyImages = propertyData.apartment_images

                   for(let x=0; x < this.propertyImages.length; x++) {
                         this.imageItems[x] = this.propertyImages[x].url
                   }
                   this.propertyData = [
                       { 'type': '', 'name': 'Bolignr', 'value': propertyData.bolignr },
                       { 'type': '', 'name': 'Boligtype', 'value': propertyData.boligtyper[0].title },
                       { 'type': '', 'name': 'Boligens brutto areal', 'value': propertyData.strelse },
                       { 'type': '', 'name': 'Ledighedstidspunkt', 'value': propertyData.availablityDate },
                       { 'type': '', 'name': 'Husleje', 'value': propertyData.monthlyRent },
                       { 'type': '', 'name': 'Adresse', 'value': propertyData.address }
                   ]

                   for(let i=0; propertyData.information.length; i++) {
                       var property = { 'type': propertyData.information[i].info_link,
                                    'name': propertyData.information[i].info_name,
                                    'value': propertyData.information[i].info_value,
                                    'link': propertyData.information[i].info_link,
                                    'target': propertyData.information[i].info_linktarget,
                                    'linkrel': propertyData.information[i].info_linkrel,
                                    'linkalt': propertyData.information[i].info_linkalt
                      }
                      this.propertyData.push(property)
                  } 
               }
           }
       }
     }

</script>

<style>
</style>
