<template>
  <div>
     <section id="page-title">
         <div class="container clearfix">
           <h1>{{ entries[0].title }}</h1>
         </div>
     </section>
     <section id="content">
       <div class="content-wrap">
         <div class="container clearfix">
           <ApartmenttypesGrid v-if="entries[0].view_type === 'grid'"
                               v-bind:apartments="apartments"
                               v-bind:apartmentAdminPage="entries"/>
           <ApartmenttypesList v-if="entries[0].view_type === 'list'"
                               v-bind:apartments="apartments"
                               v-bind:apartmentAdminPage="entries"/>
           <div class="clear"></div>
           <div class="divider divider-center"><i class="icon-circle"></i></div>          
         </div>        
       </div>
       <div id="gallery-model"></div>       
    </section>
  </div>
</template>

<script>
  import ApartmenttypesGrid from './Apartmenttypes-grid'
  import ApartmenttypesList from './Apartmenttypes-list'
  import { GET_APARTMENTTYPE_ADMIN_PAGE } from '../graphql/apartmenttypeQueries'
  import { GET_APARTMENTS } from '../graphql/apartmenttypeQueries' 
  
  export default {
      name: 'Apartmenttypes',
      props: ['globalSet'],
      components: {
          'ApartmenttypesGrid': ApartmenttypesGrid,
          'ApartmenttypesList': ApartmenttypesList
      },
      data() {
         return {
             entries: [],
             apartments: []
         }
      },
      apollo: {
         entries: GET_APARTMENTTYPE_ADMIN_PAGE,
         apartments: GET_APARTMENTS
      }
  }
  
</script>

<style>
</style>
