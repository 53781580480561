<template>
<section id="slider" class="slider-parallax full-screen dark error404-wrap" :style="'background: transparent url('+ globalSet[0].backgroundimage404page[0].url +') repeat scroll center center; transform: translate(0px, 0px); height:900px;'">
  <div style="opacity: 2.22222; position: absolute; top: 50%; width: 100%; margin-top: -175.5px;" class="container vertical-middle center clearfix">
    <div class="error404" style="opacity: 0.6">404</div>
    
    <div class="heading-block nobottomborder text-outline">
      <h4>Ups! Siden du har søgt efter findes ikke <br>- Klik eventuelt på menuen i toppen</h4>
    </div>
  </div>
</section>
</template>

<script>
    export default {
        name: 'page404',
        props: ['globalSet']
    }
</script>

<style>
</style>
