<template>
<section id="content">
    <div class="content-wrap">
      <div ref="contentcontainer" class="container clearfix">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Contactform v-bind:gaevent="'desktop'" />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="topmargin-sm bottommargin-sm">
              <div class="heading-block">
                <div class="hidden-sm hidden-md hidden-lg">
                  <img class="fadeInRightBig animated" data-animate="fadeInRightBig" alt="EDC POUL ERIK BECH" src="/assets/edc-ejendomsmaegler-kontor-odense-525.png">
                </div>


                <span>
                  <p>Lejlighederne er til leje hos EDC POUL ERIK BECH | Stationsvej 25. Er du interesseret i at bo i <strong><em>HPL-Husene&nbsp;</em></strong><em><strong><em>i Odense C.</em></strong></em>, er du meget velkommen til at kontakte ejendomsmægleren på <a href="tel:66126127">6612 6127</a> for mere information eller udfylde vores kontakt/ventelist formular.</p>
                </span>
              </div>
            </div>
            
            <div class="topmargin-sm bottommargin col_last hidden-xs">
              <img class="fadeInRightBig animated" data-animate="fadeInRightBig" alt="EDC POUL ERIK BECH" src="/assets/edc-ejendomsmaegler-kontor-odense-525.png">
            </div>
            
            <div class="col_two_third topmargin-sm bottommargin-sm">
              <div class="heading-block">
                <span>
                  <h3>EDC POUL ERIK BECH</h3>
                  <p>Morten Nancke, ejendomsmæger MDE, afdelingsleder<br>Telefon. <strong><a href="tel:66126127">66 12 61 27</a></strong></p>
                  <p>Vi glæder os til at snakke med dig!</p>
                </span>
              </div>
            </div>
            
            <div class="col_one_third topmargin-sm bottommargin col_last hidden-xs">
            </div>



          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="topmargin-sm bottommargin-sm">
              <div class="heading-block">
                <h2>Fakta om projektet:</h2>
                <span>
                  <ul><li><strong>Bygherre: </strong>L-ejendomme ApS</li><li><strong>Arkitekt:</strong>Archidea Arkitekterne</li><li><strong>Landskabs arkitekt: </strong>LAND+ APS</li><li><strong>Ingeniør: </strong>VITA Ingeniører </li><li><strong>Totalentreprenør: </strong>5E Byg A/S</li><li><strong>Byggestart: </strong>marts 2019</li><li><strong>Indflytningsklar: </strong>Juli 2020</li><li><strong>Antal lejligheder: </strong>99 lejligheder/studieboliger fordelt på 3 punkthuse med <strong>33 lejligheder</strong> i hver.</li></ul>
                </span>
              </div>
            </div>
          </div> 
         </div>
         <div v-if="gmapCenter.lat && gmapCenter.lng">
           <GmapMap :center="gmapCenter"
                    :zoom="14"
                    map-type-id="roadmap"
                    :options="gmapOptions"   
                    :style="'width: 100%; height:' + gmapHeight ">
             <GmapMarker
               :key="index"
               v-for="(m, index) in gmapMarkers"
               :position="m.position"
               infoText="test"
               :icon="m.icon"
               :clickable="true"
               :draggable="false"
               @click="toggleInfoWindow(m,index)">
             </GmapMarker>
             <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
             </GmapInfoWindow>
           </GmapMap>
         </div>
    </div>
  </section>
</template>

<script>
import Contactform from './Contactform'

export default {
   name: 'Contentrows',
   props: ['gmap', 'globalSet'],
   components: {
       Contactform
   },
   data() {
       return {
           infoWindowPos: null,
           infoWinOpen: false,
           currentMidx: null,
           setLastColumnBreak: false,
           infoOptions: {
               content: '',
               pixelOffset: {
                   width: 0,
                   height: -35
               }
           },
           gmapCenter: {},
           gmapMarkers: [],
           gmapHeight: 0,
           gmapOptions: {
               styles: [
                   {
                       "featureType": "water",
                       "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#d3d3d3"
                            }
                        ]
                   },
             {
                 "featureType": "water",
                 "elementType": "labels.text.fill",
                 "stylers": [
                     {
                         "color": "#e66d1c"
                     }
                 ]
             },
             {
                 "featureType": "transit",
                 "stylers": [
                     {
                         "color": "#808080"
                     },
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {
                 "featureType": "road.highway",
                 "elementType": "geometry.stroke",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#b3b3b3"
                     }
                 ]
             },
             {
                 "featureType": "road.highway",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#ffffff"
                     }
                 ]
             },
             {
                 "featureType": "road.local",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#ffffff"
                     },
                     {
                         "weight": 1.8
                     }
                 ]
             },
             {
                 "featureType": "road.local",
                 "elementType": "geometry.stroke",
                 "stylers": [
                     {
                         "color": "#d7d7d7"
                     }
                 ]
             },
             {
                 "featureType": "poi",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#ebebeb"
                     }
                 ]
             },
             {
                 "featureType": "administrative",
                 "elementType": "geometry",
                 "stylers": [
                     {
                         "color": "#a7a7a7"
                     }
                 ]
             },
             {
                 "featureType": "road.arterial",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#ffffff"
                     }
                 ]
             },
             {
                 "featureType": "road.arterial",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#ffffff"
                     }
                 ]
             },
             {
                 "featureType": "landscape",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#efefef"
                     }
                 ]
             },
             {
                 "featureType": "road",
                 "elementType": "labels.text.fill",
                 "stylers": [
                     {
                         "color": "#696969"
                     }
                 ]
             },
             {
                 "featureType": "administrative",
                 "elementType": "labels.text.fill",
                 "stylers": [
                     {
                         "visibility": "on"
                     },
                     {
                         "color": "#737373"
                     }
                 ]
             },
             {
                 "featureType": "poi",
                 "elementType": "labels.icon",
                 "stylers": [
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {
                 "featureType": "poi",
                 "elementType": "labels",
                 "stylers": [
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {
                 "featureType": "road.arterial",
                 "elementType": "geometry.stroke",
                 "stylers": [
                     {
                         "color": "#d6d6d6"
                     }
                 ]
             },
             {
                 "featureType": "road",
                 "elementType": "labels.icon",
                 "stylers": [
                     {
                         "visibility": "off"
                     }
                 ]
             },
             {},
             {
                 "featureType": "poi",
                 "elementType": "geometry.fill",
                 "stylers": [
                     {
                         "color": "#dadada"
                     }
                 ]
             }
              ]
           }
       }
   },
   watch: {
       gmap: function() {
           this.gmapCenter = {}
           this.gmapMarkers = []
           if(this.gmap.content_content_grid.length > 0) {
               this.feedGmap()
           }
      }
   },

   methods: {
       toggleInfoWindow: function(marker, idx) {
           this.infoWindowPos = marker.position;
           this.infoOptions.content = marker.infoText;

           //check if its the same marker that was selected if yes toggle
           if (this.currentMidx == idx) {
             this.infoWinOpen = !this.infoWinOpen;
           }
           //if different marker set infowindow to open and reset current marker index
           else {
             this.infoWinOpen = true;
             this.currentMidx = idx;
           }
      },
      lastcolumnbreak(value) {
          return value;
      },
      feedGmap() {
          this.gmapCenter = {
              lat: parseFloat(this.globalSet[0].defaultGmapLat),
              lng: parseFloat(this.globalSet[0].defaultGmapLng)
          }
          this.gmapHeight = (this.globalSet[0].gmapHeight) + 'px'
          var gmapMarkers = this.gmap.content_content_grid
          for(let i = 0; i < gmapMarkers.length; i++) {

               var infoText = '<h4 class="nomargin">'
                   + gmapMarkers[i].markerHeadline + '</h4><div>'
                   + gmapMarkers[i].markerText + '</div>'
                   if(gmapMarkers[i].markerLink) {
                       infoText = infoText + '<div><a target="_blank" href="'
                                + gmapMarkers[i].markerLink + '" rel="nofollow">'
                                + gmapMarkers[i].markerLinkText + '</a></div><div>'
                   }
                   if(gmapMarkers[i].markerImage.length > 0) {
                       infoText = infoText + '<div><img src="'
                                + gmapMarkers[i].markerImage[0].url.replace('default/uploads/','default/uploads/_googleMapImage/') +'"></div>'
                   }                    
                             
               var marker = {
                   position: {
                       lat: parseFloat(gmapMarkers[i].latitude),
                       lng: parseFloat(gmapMarkers[i].longtitude)
                   },
                   icon: {
                       url: gmapMarkers[i].markerIcon[0].url
                   },
                   infoText: infoText
               }                     
               this.$set(this.gmapMarkers, i, marker)
        }
      },
      getThumb(imgUrl) {
          var imgpath = imgUrl.replace('galleri/','galleri/_carousel_thumbs/')
          imgpath = imgpath.replace('default/uploads/','default/uploads/_carousel_thumbs/')
          return imgpath
      },
      mediapos (text,width,position) {
         var output1 = ''
         var output2 = ''
         if(text == '') {
            output1 = 'col_' + width + ' '
         } else {
            output1 = 'col_' + width + ' '
         }
         if(position == 'right') {
            output2 = 'col_last '
         }
         return output1 + output2
      },
      getopsitewith (width) {
         switch(width) {
            case 'half':
               return 'col_half'
            case 'one_third':
               return 'col_two_third'
            case 'two_third':
               return 'col_one_third'
            case 'one_forth':
               return 'col_three_forth'
            case 'three_forth':
               return 'col_one_forth'
            case 'one_fifth':
               return 'col_four_fifth'
            case 'two_fifth':
               return 'col_three_fifth'
            case 'three_fifth':
               return 'col_two_fifth'
            case 'four_fifth':
               return 'col_one_fifth'
            case 'one_sixth':
               return 'col_five_sixth'
            case 'five_sixth':
               return 'col_one_sixth'
            default:
               return 'col_full'
         }
      }
   }
}
</script>

<style>
</style>
