<template>
  <div>
    <form v-on:submit="onSubmit">
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-firstName" class="required">Navn</label>
          <input class="form-control" v-model="firstName" name="firstName" type="text" id="form-input-firstName" placeholder="Indtast navn">
          <ul v-if="getErrors('firstName').length" class="errors">
            <li v-for="error in getErrors('firstName')" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-Postnr-by" class="required">Postnr./by</label>
          <input class="form-control" v-model="postnrby" name="Postnr-by" type="text" id="form-input-Postnr-by" placeholder="Indtast postnr./by">
          <ul v-if="getErrors('Postnr-by').length" class="errors">
            <li v-for="error in getErrors('Postnr-by')" :key="error">
              {{ error }}
            </li>
          </ul>          
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-email" class="required">Email</label>
          <input class="form-control" v-model="email" name="email" type="email" id="form-input-email" placeholder="Indtast email">
          <ul v-if="getErrors('email').length" class="errors">
            <li v-for="error in getErrors('email')" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-cellPhone" class="required">Telefon</label>
          <input class="form-control" v-model="cellPhone" name="cellPhone" type="text" id="form-input-cellPhone" placeholder="Indtast telefonnummer">
          <ul v-if="getErrors('cellPhone').length" class="errors">
            <li v-for="error in getErrors('cellPhone')" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-message" class="">Kommentar</label>
          <textarea class="form-control" v-model="message" name="message" id="form-input-message" rows="5" placeholder="Skriv eventuel kommentar"></textarea>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-nuvrendeBoligform" class="required">Hvad er din nuværende boligform?</label>
          <select class="form-control" v-model="nuvrendeBoligform" name="nuvrendeBoligform" id="form-input-nuvrendeBoligform"><option value="Lejebolig" selected="">Lejebolig</option><option value="Ejerbolig">Ejerbolig</option></select>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <label for="form-input-boligtype" class="required">Boligtype</label>
          <select class="form-control" v-model="boligtype" name="boligtype" id="form-input-boligtype"><option value="Lejlighed type A">Lejlighed type A</option><option value="Lejlighed type B">Lejlighed type B</option><option value="Lejlighed type C">Lejlighed type C</option><option value="Lejlighed type D">Lejlighed type D</option></select><div class="help-block">Vælg den lejlighedstype du ønsker at blive skrevet på ventelist til</div>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12">
          <div>Det er uforpligtigende, og du modtager kun e-mails i relation til HPL-Husene i Odense. Vi videresender naturligvis ikke dine kontaktdata til andre end mægleren.</div>
        </div>
      </div>
      <div class="row ">
        <div class="form-group col-sm-12 col-xs-12 submit-align-left">
          <button class="btn btn-default button" type="submit" name="form_page_submit">Kontakt/tilmeld venteliste</button>
        </div>
      </div>
    </form>

      <div class="vld-parent">
    <Loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"
             :color="loadingColor"
             ></Loading>
  </div>
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/vue-loading.css'
  
  export default {
  name: 'Contact form',
  props: ['gaevent'],
  delimiters: ['${', '}'],
  data() {
    return {
      isLoading:false,
      fullPage: true,
      loader: 'dots',
      loadingColor: '#f89520',
      loadingOpacity: 0.5,
      firstName: '',
      email: '',
      postnrby: '',
      cellPhone: '',
      message: '',
      nuvrendeBoligform: '',
      boligtype: '',
      errormessage: 'Feltet skal udfyldes',
      errors: [],
   }
  },
  components: {
      Loading
  },
  methods: {
      getErrors: function (property) {
      const errors = [];
      if (!this.errors.length) {
          return errors;
        }
        for (let i = 0; i < this.errors.length; i++) {
          if (this.errors[i].key === property) {
            errors.push(this.errors[i].message);
          }
        }
        return errors;
      },
      validate: function (property) {
        if (!property) {
          this.errors = [];
        } else {
          this.errors = this.errors.filter((item) => item.key !== property);
        }
        if ((!property || property === 'firstName') && !this.firstName) {
          this.errors.push({ key: 'firstName', message: this.errormessage });
        }
        if ((!property || property === 'email') && !this.email) {
          this.errors.push({ key: 'email', message: this.errormessage });
        }
        if ((!property || property === 'postnrby') && !this.postnrby) {
          this.errors.push({ key: 'Postnr-by', message: this.errormessage });
        }
        if ((!property || property === 'cellPhone') && !this.cellPhone) {
          this.errors.push({ key: 'cellPhone', message: this.errormessage });
        }
      },
      onSubmit: function (evt) {
        this.validate();
        if (this.errors.length) {
         evt.stopPropagation();
         evt.preventDefault();
          return false;
        }
        this.isLoading = true;
        const form = evt.target;
        const data = new FormData(form);
        const request = new XMLHttpRequest();

        // Add Honeypot field
        data.append('freeform_form_handle', '');

        data.append('freeform_payload', window.contactFormPayload);
        data.append(window.csrfTokenName,window.csrfTokenValue);

        // Add action which handles freeform validation
        data.append('action', 'freeform/submit');

        // Add the form's hash, so that it gets recognized by the form service
        data.append('formHash', window.contactFormHash);
        // Append the data
        data.append('firstName', this.firstName);
        data.append('Postnr-by', this.postnrby);
        data.append('email', this.email);
        data.append('cellphone', this.cellphone);
        data.append('message', this.message);
        data.append('nuvrendeBoligform', this.nuvrendeBoligform);
        data.append('boligtype', this.boligtype);
    
        // Create a request
        request.open('POST', 'https://hpl-husene.dk/', true);
        request.setRequestHeader("Cache-Control", "no-cache");

        // Add the AJAX headers specific to Craft CMS
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest");

        // Attach the response handler callback
        request.onload = () => {

        this.isLoading = false
        this.$gtag.event('Tilmeld',
            {'event_category':'Venteliste',
             'event_label':this.gaevent,
             'value': 1}
        )
        if (request.status === 200) {
            const response = JSON.parse(request.response);
            const { success, finished, errors, formErrors } = response
            if (success && finished) {
              // Reset the form so that the user may enter fresh information
              form.reset();
              window.location.href = response.returnUrl
            } else if (errors || formErrors) {
              alert('error');
            }
          }
        };

        // Send the request
        request.send(data);

        evt.stopPropagation();
        evt.preventDefault();
        return false;
      },
    },
  }

</script>

<style>
</style>
