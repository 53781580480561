<template>
   <div>
     <section id="page-title">
       <div class="container clearfix">
         <h1>Beboermappe<a id="logout" class="hidden-sm hidden-xs" href="">Log af</a></h1>
       </div>
     </section>
     <section id="content">
       <div class="content-wrap">
         <div class="container clearfix">
           <div class="mappe row" v-if="residentinfo">
             <div class="col-md-6 col-xs-12" v-if="residentinfo.building">
               <p class="h2 nobottommargin notopmargin"></p>
               <div v-for="info in residentinfo.building[0].info" :key="info">
               <p class="nobottommargin headline">Særlig besked:<span class="pull-right">Dato: {{ info.date | moment('timezone', 'Europe/Copenhagen', 'DD/MM/YY HH:mm:ss') }}</span></p>
               <div class="grey-box" v-html="info.text">
               </div>
               </div>

               <p class="h2 nobottommargin topmargin-xs">Indholdsfortegnelse</p>
               <p class="bottommargin-xs">Under hvert menupunkt ligger der en PDF, som beskriver forhold og regler omkring ejendommen og dit eget lejemål samt diverse bruger- og produktmanualer.</p>
               <table>
                 <tr>
                   <th>Fil</th>
                   <th>Sidst opdateret</th>
                 </tr>
                 <tr v-for="file in residentinfo.building[0].files" :key="file">
                   <td><a :href="file.file[0].url" target="_blank">{{ file.file_name }}</a></td>
                   <td>{{ file.date | moment('timezone', 'Europe/Copenhagen', 'DD/MM/YY HH:mm:ss') }}</td>
                 </tr>
               </table>
             </div>
             <div v-if="residentinfo.building" class="col-md-6 hidden-sm hidden-xs">
               <img :src="residentinfo.building[0].blok_mappe_billede[0].url">
             </div>
           </div>
         </div>
         </div>
         </section>
       </div>
</template>

<script>
  import { GET_RESIDENTINFO_QUERY } from '../graphql/buildingQueires'
  
  export default {
  name: 'Residentinfo',
  props: ['theUser'],
  data () {
      return{
          residentinfo: []

      }
  },
  created() {
      this.fetchData()
  },
  methods: {
         fetchData () {
             this.$apollo.query({
                 query: GET_RESIDENTINFO_QUERY,
                 variables: {
                     "user": this.theUser.loginName
                 }
              }).then(function (response) {
                  if(this.theUser.loginName != null) {
                      this.residentinfo = response.data
                      this.residentinfo.building[0].info.reverse()
                  }
              }.bind(this))
         }
  }
  }  
</script>

<style>
</style>
