import gql from 'graphql-tag'

export const GET_GALLERIES = gql`
query {
  entries (section:"gallery" ) {
    ... on gallery_galleri_Entry {
      title,
      slug,
      images {
        url,
        title
      }
    }
  }
}`
