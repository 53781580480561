import Vue from 'vue';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import VueApollo from 'vue-apollo';

const getHeaders = () => {
    const headers = {};
    headers.authorization = 'Bearer jMXMQKVD48LmYnZbZ7BgzvFz6fPOMg8y';
    return headers;
};

const httpLink = createHttpLink({
    uri: 'https://hpl-husene.dk/api',
    headers: getHeaders()
})

const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache: cache,
    connectToDevTools: true,
});

Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
