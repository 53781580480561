<template>
  <div>
    <section id="page-title">
      <div class="container clearfix">
	<h1>Kontakt og venteliste</h1>
      </div>
    </section>
    <Contentrows v-bind:gmap="gmap"
                 v-bind:globalSet="globalSet"/>
  </div>
</template>

<script>
  import ContentpageContentrows from './ContactpageContentrows'

  export default {
     name: 'Contentpage',
     props: ['globalSet'],
     components: {
         Contentrows: ContentpageContentrows
     },
     data() {
       return {
       }
     }
   }
</script>

<style>
</style>
