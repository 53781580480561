<template>
  <div>
  <Slider/>
  <Contentrows v-bind:contentrows="entries" v-bind:globalSet="globalSet"/>
  </div>
</template>

<script>
  import Slider from './Slider'
  import Contentrows from './Contentrows'
  import { GET_FRONTPAGE_CONTENTROWS_QUERY } from '../graphql/contentrowsQueries';

  export default {
     name: 'Frontpage',
     props: ['globalSet'],
     components: {
        'Slider': Slider,
        'Contentrows': Contentrows
     },
     data() {
       return {
          entries: []
       }
     },
     apollo: {
       entries: { query: GET_FRONTPAGE_CONTENTROWS_QUERY }
     }
  }
</script>

<style>
</style>
