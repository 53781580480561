import gql from 'graphql-tag'

export const GET_APARTMENTTYPE_ADMIN_PAGE = gql`
query {
  entries (section:"adminchannel", slug:"boligtyper") {
    ... on adminchannel_adminchannel_Entry {
        title,
        view_type,
        apartment_file(type: "apartmentfiles") {
        ... on apartment_file_apartmentfiles_BlockType {
          file { 
            url 
          },
          fileName
        }
      }
    }
  }
}`

export const GET_APARTMENTS = gql`
query {
  apartments: entries (section:"boligtyper") {
    ... on boligtyper_boligtyper_Entry {
        amount_of_rooms,
        description,
        title,
        image {
          url,
          title
        },
        slug,
        blok,
        floor,
        features(type: "faktaOmBoligen") {
           ... on features_faktaOmBoligen_BlockType {
              feature,
              headline
           }
      },
      link_360,
      vr_link
      media(type: "mediafiles") {
          ... on media_mediafiles_BlockType {
            mediatype,
            vimeoId,
            image {
              url,
              title
            }
          }
      },
      krpano_xml_path,
      krpano_xml_path_vrview,
      size,
      attached_files(type: "tilknyttetFiler") {
        ... on attached_files_tilknyttetFiler_BlockType {
          filename,
          file {
            url,
            title
          }
        }
      }
    }
  }
}`
