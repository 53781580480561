import gql from 'graphql-tag'

export const GET_BUILDINGS_QUERY = gql`
query ($slug:[String]) {
  building: entries (section:"boligblok", slug: $slug) {
  ... on boligblok_boligblok_Entry {
    id,
    title,
    blok_navn,
    debug,
    buildingLabel,
    uri,
    extra_billede {
        url,
        title
    },
    extra_billede_title,
    facade {
        url,
        title
    },
    facadeBilledeBredde,
    facade_title,
    extra_information,
    lejligheds_typer,
    supplementaryBuildingText,
    associatedBuildings {
        title,
        facade {
           url,
           title
        },
        uri
    }
  }
  }
}`

export const GET_RESIDENTINFO_QUERY = gql`
query($user:[QueryArgument])  {
  building: entries (section:"boligblok", residentUser: $user) {
  ... on boligblok_boligblok_Entry {
       title,
     	 blok_mappe_billede {
          url
       },
   },
  },
  building: entries (section:"boligblok", residentUser: $user) {
  ... on boligblok_boligblok_Entry {
  files: beboermappe(type: "indholdsfortegnelse") {
     ... on beboermappe_indholdsfortegnelse_BlockType {
        file_name,
        file{
           url
        },
        date
    }
  }
  }
  },
  building: entries (section:"boligblok", residentUser: $user) {
  ... on boligblok_boligblok_Entry {
  info: beboermappe(type: "informationTilBeborerne") {
     ... on beboermappe_informationTilBeborerne_BlockType {
        text,
        date
    }
  }
  }
  }
}`

export const GET_ALL_PROPERTY_QUERY = gql`

query {
  property: entries (section:"residential") {
  ... on residential_residential_Entry {
    bolig_blok {
      ... on boligblok_boligblok_Entry {
        title
      }
    },
    apartment_images {
        url,
        title
    },
    bolignr,
    boligtyper {
      ... on boligtyper_boligtyper_Entry {
        title
        amount_of_rooms,
        floor,
        size
      }
      
    }
    width,
    height,
    information(type: "information") {
      ... on information_information_BlockType {
        info_name,
        info_value,
        info_link,
        info_linktarget,
        info_linkrel,
        info_linkalt
      }
    }
    monthlyRent,
    address,
    availablityDate,
    lejlighed_status,
    lejligheds_type,
    marking_color,
    strelse,
    click_disabled,
    udbudstekst,
    udbudsknap,
    coordinate_x,
    coordinate_y
  }
  }
}`

export const GET_PROPERTY_QUERY = gql`
query ($building:[QueryArgument]) {
  property: entries (section:"residential", bolig_blok: $building) {
  ... on residential_residential_Entry {
    apartment_images {
        url,
        title
    },
    bolignr,
    boligtyper {
      title
    }
    width,
    height,
    information(type: "information") {
      ... on information_information_BlockType {
        info_name,
        info_value,
        info_link,
        info_linktarget,
        info_linkrel,
        info_linkalt
      }
    }
    monthlyRent,
    address,
    availablityDate,
    lejlighed_status,
    lejligheds_type,
    marking_color,
    strelse,
    click_disabled,
    udbudstekst,
    udbudsknap,
    coordinate_x,
    coordinate_y
  }
  }
}`
