import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { apolloProvider } from './graphql/apollo';
import * as VueGoogleMaps from "vue2-google-maps";
import vueScrollto from 'vue-scrollto'
import VueSwal from 'vue-swal'
import VueGtag from 'vue-gtag'
import VueLazyload from 'vue-lazyload'

import Page404 from './components/404'
import Frontpage from './components/Frontpage'
import Contentpage from './components/Contentpage'
import Contactpage from './components/Contactpage'
import Buildingview from './components/Buildingview'
import Gallery from './components/Gallery'
import Apartmenttypes from './components/Apartmenttypes'
import Videocontent from './components/Videocontent'
import Buildinginfo from './components/Buildinginfo'
import Residentlogin from './components/Residentlogin'
import Apartmentlist from './components/Apartmentlist'

import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
Vue.use(VueFilterDateParse);
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
Vue.use(VueFilterDateFormat);

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
 
Vue.use(VueMoment, {
    moment,
})
import VueResource from 'vue-resource'
Vue.use(VueResource)
Vue.http.options.emulateJSON = true
const http=Vue.http
export default http

Vue.use(VueRouter)
Vue.use(vueScrollto)
Vue.use(VueSwal)

Vue.use(VueLazyload, {
  preLoad: 0.7,
  attempt: 3
})

Vue.use(VueGtag, {
    config: {
        id: "UA-157544384-1",
        routes,
    }
});

const routes = [
    { path: '*', component: Page404, props:true },
    { path: '/', component: Frontpage, props:true },
    { path: '/leje-lejligheder-studieboliger-odense-city-campus', component: Contentpage, props:true },
    { path: '/tak-kontakt-venteliste-leje-lejligheder', component: Contentpage, props:true},
    { path: '/odense-studieby-city-campus', component: Videocontent, props:true },
    { path: '/leje-lejligheder-studieboliger-odense-oversigt', component: Buildingview, props:true },
    { path: '/lejlighed-type-a-odense-city', component: Videocontent, props: true },
    { path: '/lejlighed-type-c-odense-city', component: Videocontent, props:true },
    { path: '/seebladsgade-lejligheder-odense-city-campus', component: Videocontent, props:true },
    { path: '/ledige-lejligheder', component: Apartmentlist, props:true },
    { path: '/galleri', component: Gallery, props:true },
    { path: '/kontakt', component: Contactpage, props:true },
    { path: '/boligtyper', component: Apartmenttypes, props:true },
    { path: '/bygning/lejlighedsoversigt-bygning-11-nord-odense', component: Buildinginfo, props:true },
    { path: '/bygning/lejlighedsoversigt-bygning-11-syd-odense', component: Buildinginfo, props:true },
    { path: '/bygning/lejlighedsoversigt-bygning-9-nord-vest-odense', component: Buildinginfo, props:true },
    { path: '/bygning/lejlighedsoversigt-bygning-9-syd-oest-odense', component: Buildinginfo, props:true },
    { path: '/bygning/lejlighedsoversigt-bygning-7-vest-odense', component: Buildinginfo, props:true },
    { path: '/bygning/lejlighedsoversigt-bygning-7-oest-odense', component: Buildinginfo, props:true },
    { path: '/beboermappe', component: Residentlogin, props: true },
    { path: '/cookiepolitik', component: Contentpage, props:true },
]

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    mode: 'history'
})

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA6abmDciKj52AItJKsYFgRAb_iseMMVbk",
    libraries: "places" // necessary for places input
  }
});

new Vue({
    router,
    apolloProvider,
    render: h => h(App)
}).$mount('#app')
